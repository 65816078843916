import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Radio } from '@material-ui/core';
import ConfigurationList from './ConfigurationList';
import ZoomableImage from '../../Components/ZoomableImage/ZoomableImage';

const Covers = () => {

    return (
        <ConfigurationList>
            {(currentConfiguration, _, deckType, markCompleted) => {

                const [selectedCoverTypeId, setCoverTypeId] = currentConfiguration.coverType;

                return (
                    <List disablePadding>
                        {deckType.coverTypes.map((coverType) => {

                            const isSelected = selectedCoverTypeId ? selectedCoverTypeId === coverType.id : false;

                            return (
                                <ListItem
                                    disableGutters
                                    key={coverType.id}
                                >
                                    <ListItemAvatar>
                                        <ZoomableImage
                                            src={coverType.url}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={coverType.name}
                                        secondary={coverType.description}
                                    />
                                    <ListItemSecondaryAction>
                                        <Radio
                                            onChange={() => {

                                                setCoverTypeId(coverType.id);
                                                markCompleted();

                                            }}
                                            checked={isSelected}
                                            color={'primary'}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                );
            }}

        </ConfigurationList>
    );
};

export default Covers;
