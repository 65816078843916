import { ClientDeckConfiguration, EditDeckConfiguration } from '../Types/ConfigurationTypes';
import { Decks } from '../Types/DeckTypes';

const useClientConfiguration = (clientConfiguration: EditDeckConfiguration, decks: Decks): ClientDeckConfiguration => {

    const deckType = decks.find(d => d.id === clientConfiguration.deckType[0]);

    if (!deckType) {
        return {
            id: clientConfiguration.id,
            configurationStatus: clientConfiguration.configurationStatus[0],
            width: clientConfiguration.width[0],
            height: clientConfiguration.height[0],
            depth: clientConfiguration.depth[0],
            deckType: null,
            roofType: null,
            colorType: null,
            coverType: null,
            extraItems: [],
            freeItems: [],
        };
    }

    return {
        deckType,
        id: clientConfiguration.id,
        configurationStatus: clientConfiguration.configurationStatus[0],
        width: clientConfiguration.width[0],
        height: clientConfiguration.height[0],
        depth: clientConfiguration.depth[0],
        roofType: deckType.roofTypes.find(r => r.id === clientConfiguration.roofType[0]),
        colorType: deckType.colorTypes.find(c => c.id === clientConfiguration.colorType[0]),
        coverType: deckType.coverTypes.find(c => c.id === clientConfiguration.coverType[0]),
        extraItems: clientConfiguration.extraItems[0].map((configurationExtraItem) => {
            const serverExtraItem = deckType.extraItems
                .find(deckExtraItem => deckExtraItem.id  === configurationExtraItem.selectedId);

            if (!serverExtraItem) {
                throw Error('Could not resolve serverExtraItem.');
            }

            return serverExtraItem;
        }),
        freeItems: clientConfiguration.freeItems[0],
    };
};

export default useClientConfiguration;
