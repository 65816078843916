import { Button, TextField, InputAdornment } from '@material-ui/core';
import ButtonBar from '../../Components/ButtonBar/ButtonBar';

import s from './SettingsSite.css';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useSnackbar } from '@creatdevsolutions/notistack';
import Logger from 'logplease';

const logger = Logger.create('SettingsSite.tsx');

type NumberFieldProps = {
    name: string,
    label: string,
    helperText: string,
    value: number,
    isPercent?: boolean,
    onChange: (newValue: number) => void;
};

const NumberField = (props: NumberFieldProps) => {
    return (
        <TextField
            fullWidth
            margin={'normal'}
            onChange={e => props.onChange(Number(e.target.value))}
            variant={'outlined'}
            type={'number'}
            label={props.label}
            name={props.name}
            helperText={props.helperText}
            value={props.value}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {props.isPercent ? '%' : '€'}
                    </InputAdornment>
                ),
            }}
        />
    );
};

const SettingsSite = () => {

    const userSession = useContext(UserContext);
    const snackbarContext = useSnackbar();

    const [glassPanel, setGlassPanel] = useState(0);
    const [glassPanelMatt, setGlassPanelMatt] = useState(0);
    const [polyPanel, setPolyPanel] = useState(0);
    const [post, setPost] = useState(0);
    const [traverse, setTraverse] = useState(0);
    const [traverseCover, setTraverseCover] = useState(0);
    const [traverseSideCover, setTraverseSideCover] = useState(0);
    const [channel, setChannel] = useState(0);
    const [channelCover, setChannelCover] = useState(0);
    const [channelSideCover, setChannelSideCover] = useState(0);
    const [structural, setStructural] = useState(0);
    const [structuralCover, setStructuralCover] = useState(0);
    const [structuralSideCover, setStructuralSideCover] = useState(0);
    const [wallProfile, setWallProfile] = useState(0);
    const [wallProfileSideCover, setWallProfileSideCover] = useState(0);
    const [rubberGasketProfiles, setRubberGasketProfiles] = useState(0);
    const [pipingBend, setPipingBend] = useState(0);
    const [pipingPipe, setPipingPipe] = useState(0);
    const [pipingDirtCatcher, setPipingDirtCatcher] = useState(0);
    const [rubberBig, setRubberBig] = useState(0);
    const [rubberSmall, setRubberSmall] = useState(0);
    const [insideCover, setInsideCover] = useState(0);
    const [panelStopper, setPanelStopper] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [assemblyKit, setAssemblyKit] = useState(0);
    const [tempDiscount, setTempDiscount] = useState(0);

    useEffect(() => {
        const connectorInstance = userSession.getInstance();

        connectorInstance.getPartPrices().then((partPrices) => {
            setGlassPanel(partPrices.glassPanel);
            setGlassPanelMatt(partPrices.glassPanelMatt);
            setPolyPanel(partPrices.polyPanel);
            setPost(partPrices.post);
            setTraverse(partPrices.traverse);
            setTraverseCover(partPrices.traverseCover);
            setTraverseSideCover(partPrices.traverseSideCover);
            setChannel(partPrices.channel);
            setChannelCover(partPrices.channelCover);
            setChannelSideCover(partPrices.channelSideCover);
            setStructural(partPrices.structural);
            setStructuralCover(partPrices.structuralCover);
            setStructuralSideCover(partPrices.structuralSideCover);
            setWallProfile(partPrices.wallProfile);
            setWallProfileSideCover(partPrices.wallProfileSideCover);
            setRubberGasketProfiles(partPrices.rubberGasketProfiles);
            setPipingBend(partPrices.pipingBend);
            setPipingPipe(partPrices.pipingPipe);
            setPipingDirtCatcher(partPrices.pipingDirtCatcher);
            setRubberBig(partPrices.rubberBig);
            setRubberSmall(partPrices.rubberSmall);
            setInsideCover(partPrices.insideCover);
            setPanelStopper(partPrices.panelStopper);
            setShipping(partPrices.shipping);
            setAssemblyKit(partPrices.assemblyKit);
            setTempDiscount(partPrices.tempDiscount);

        });
    },        []);

    const savePartPrices = () => {
        const connectorInstance = userSession.getInstance();

        connectorInstance.savePartPrices({
            glassPanel,
            glassPanelMatt,
            post,
            polyPanel,
            traverse,
            traverseCover,
            traverseSideCover,
            channel,
            channelCover,
            channelSideCover,
            structural,
            structuralCover,
            structuralSideCover,
            wallProfile,
            wallProfileSideCover,
            rubberGasketProfiles,
            pipingBend,
            pipingPipe,
            pipingDirtCatcher,
            rubberBig,
            rubberSmall,
            insideCover,
            panelStopper,
            shipping,
            assemblyKit,
            tempDiscount,
        }).then(() => {
            snackbarContext.enqueueSnackbar('Die Preise wurden erfolgreich gespeichert', { variant: 'success' });
        }).catch((e) => {
            logger.error(e);
            snackbarContext.enqueueSnackbar('Die Preise konnten nicht gespeichert werden.', { variant: 'error' });
        });

    };

    return (
        <div className={s.SettingsSite}>
            <ButtonBar title={'Einstellungen'}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={savePartPrices}
                >
                    Speichern
                </Button>
            </ButtonBar>
            <div className={s.SettingsEntries}>
                <NumberField
                    label="Bedachung (Glas)"
                    helperText={'Pro Scheibe (80cmx100cm)'}
                    name={'glassPanel'}
                    value={glassPanel}
                    onChange={setGlassPanel}
                />
                <NumberField
                    label="Bedachung (Glas) (matt)"
                    helperText={'Pro Scheibe (80cmx100cm)'}
                    name={'glassPanelMatt'}
                    value={glassPanelMatt}
                    onChange={setGlassPanelMatt}
                />
                <NumberField
                    label="Bedachung (Polycharbonat)"
                    name={'polyPanel'}
                    helperText={'Pro Scheibe (100cmx100cm)'}
                    value={polyPanel}
                    onChange={setPolyPanel}
                />
                <NumberField
                    label="Pfosten"
                    name={'post'}
                    helperText={'Pro Meter'}
                    value={post}
                    onChange={setPost}
                />
                <NumberField
                    label="Querträger TR15"
                    name={'traverse'}
                    helperText={'Pro Meter'}

                    value={traverse}
                    onChange={setTraverse}
                />
                <NumberField
                    label="Alu-Deckleiste Querträger"
                    name={'traverseCover'}
                    helperText={'Pro Meter'}

                    value={traverseCover}
                    onChange={setTraverseCover}
                />
                <NumberField
                    label="Alu-Deckleiste Seitenträger"
                    name={'traverseSideCover'}
                    helperText={'Pro Meter'}

                    value={traverseSideCover}
                    onChange={setTraverseSideCover}
                />
                <NumberField
                    label="Rinnenprofil TR15"
                    name={'channel'}
                    helperText={'Pro Meter'}

                    value={channel}
                    onChange={setChannel}
                />
                <NumberField
                    label="Rinnenblende"
                    name={'channelCover'}
                    helperText={'Pro Meter'}

                    value={channelCover}
                    onChange={setChannelCover}
                />
                <NumberField
                    label="Endkappe Rinnenprofil"
                    name={'channelSideCover'}
                    helperText={'Pro Stück'}

                    value={channelSideCover}
                    onChange={setChannelSideCover}
                />
                <NumberField
                    label="Statikprofil TR15"
                    name={'structural'}
                    helperText={'Pro Meter'}

                    value={structural}
                    onChange={setStructural}
                />
                <NumberField
                    label="Statikprofilblende"
                    name={'structuralCover'}
                    helperText={'Pro Meter'}

                    value={structuralCover}
                    onChange={setStructuralCover}
                />
                <NumberField
                    label="Statikprofil Endkappe"
                    name={'structuralSideCover'}
                    helperText={'Pro Stück'}

                    value={structuralSideCover}
                    onChange={setStructuralSideCover}
                />
                <NumberField
                    label="Wandprofil"
                    name={'wallProfile'}
                    helperText={'Pro Meter'}

                    value={wallProfile}
                    onChange={setWallProfile}
                />
                <NumberField
                    label="Endkappe Wandprofil"
                    name={'wallProfileSideCover'}
                    helperText={'Pro Stück'}

                    value={wallProfileSideCover}
                    onChange={setWallProfileSideCover}
                />
                <NumberField
                    label="Dichtungsgummi für Wand-/Rinnenprofil (schwarz)"
                    name={'rubberGasketProfiles'}
                    helperText={'Pro Meter'}

                    value={rubberGasketProfiles}
                    onChange={setRubberGasketProfiles}
                />
                <NumberField
                    label="PVC-Bogen 90 Grad"
                    name={'pipingBend'}
                    helperText={'Pro Stück'}

                    value={pipingBend}
                    onChange={setPipingBend}
                />
                <NumberField
                    label="PVC-Rohr"
                    name={'pipingPipe'}
                    helperText={'Pro Stück'}

                    value={pipingPipe}
                    onChange={setPipingPipe}
                />
                <NumberField
                    label="Schmutzfänger (PVC)"
                    name={'pipingDirtCatcher'}
                    helperText={'Pro Stück'}

                    value={pipingDirtCatcher}
                    onChange={setPipingDirtCatcher}
                />
                <NumberField
                    label="Auflagegummi 8.3mm"
                    name={'rubberBig'}
                    helperText={'Pro Meter'}

                    value={rubberBig}
                    onChange={setRubberBig}/>
                <NumberField
                    label="Auflagegummi 3.3mm"
                    name={'rubberSmall'}
                    helperText={'Pro Meter'}

                    value={rubberSmall}
                    onChange={setRubberSmall}
                />
                <NumberField
                    label="Klickblende (Rinnen-/Wandprofil)"
                    name={'insideCover'}
                    helperText={'Pro Meter'}

                    value={insideCover}
                    onChange={setInsideCover}
                />
                <NumberField
                    label="Plattenstopper"
                    name={'panelStopper'}
                    helperText={'Pro Stück'}
                    value={panelStopper}
                    onChange={setPanelStopper}
                />
                <NumberField
                    label="Versand"
                    name={'shipping'}
                    helperText={'Pro Stück'}

                    value={shipping}
                    onChange={setShipping}
                />
                <NumberField
                    label="Montagesatz"
                    name={'assemblyKit'}
                    helperText={'Pro Stück'}
                    value={assemblyKit}
                    onChange={setAssemblyKit}

                />

            </div>
            <ButtonBar title={'Rabatte'}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={savePartPrices}
                >
                    Speichern
                </Button>
            </ButtonBar>
            <div className={s.SettingsEntries}>
                <NumberField
                    label="Rabatt"
                    name={'tempDiscount'}
                    helperText={'In Prozent'}
                    value={tempDiscount}
                    onChange={setTempDiscount}
                    isPercent

                />
            </div>

        </div>
    );
};

export default SettingsSite;
