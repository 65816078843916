import { StateEntry } from '../Context/Types';
import { User } from './UserTypes';
import { ColorType, CoverType, DeckType, RoofType, ResolvedExtraItem } from './DeckTypes';

export type Address = {
    id?: string,
    firstName: string,
    lastName: string,
    streetName: string,
    streetNumber: string,
    zipCode: string,
    city: string,
};

export type ExtraItem = {
    id?: string,
    selectedId: string,
    itemCount: number,
    isRequired: boolean,
};

export type FreeItem = {
    id?: string,
    name: string,
    unitPrice: number,
};

export type FreeItemWithFakeId = FreeItem & {
    fakeId: string,
};

export type EditDeckConfiguration = {
    id?: number,
    configurationStatus: StateEntry<ConfigurationStatus | null>,
    width: StateEntry<number>,
    depth: StateEntry<number>,
    height: StateEntry<number>,
    roofType: StateEntry<string | null>,
    deckType: StateEntry<string | null>,
    colorType: StateEntry<string | null>,
    coverType: StateEntry<string | null>,
    extraItems: StateEntry<ExtraItem[]>,
    freeItems: StateEntry<FreeItemWithFakeId[]>,
};

export type ClientDeckConfiguration = {
    id?: number,
    configurationStatus: ConfigurationStatus | null,
    width: number,
    height: number,
    depth: number,
    deckType?: DeckType | null,
    roofType?: RoofType | null,
    colorType?: ColorType | null,
    coverType?: CoverType | null,
    extraItems: ResolvedExtraItem[],
    freeItems: FreeItemWithFakeId[],
};

export enum ConfigurationStatus {
    SAVED= 'SAVED',
    OFFER_REQUESTED= 'OFFER_REQUESTED',
    OFFER_CONFIRMED = 'OFFER_CONFIRMED',
    ORDERED= 'ORDERED',
}

export type ServerDeckConfiguration = {
    id?: number,
    configurationStatus: ConfigurationStatus
    width: number,
    depth: number,
    height: number,
    roofType: string | null,
    deckType: string | null,
    colorType: string | null,
    coverType: string | null,
    billingAddress: Address,
    shippingAddress: Address,
    extraItems: ExtraItem[],
    freeItems: FreeItem[],
    user: User,
};
