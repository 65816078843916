import React, { useEffect, useState } from 'react';
import Two from 'two.js';
import Logger from 'logplease';
import s from './Drawing.css';

const logger = Logger.create('Drawing.tsx');

type Props = {
    width: number,
    height: number,
    depth: number,
    isStandalone: boolean,
};

const Drawing = (props: Props) => {

    const divReference = React.createRef<HTMLDivElement>();
    const [twoInstance, setTwoInstance] = useState<any>(null);

    const [clientWidth, setClientWidth] = useState<number>(0);
    const clientHeight = Math.floor(clientWidth * 9 / 16);

    const {
        width,
        height,
        depth,
        isStandalone,
    } = props;

    useEffect(() => {

        if (!divReference.current) {
            return;
        }

        const twoInstance = new Two({
            width: clientWidth,
            height: clientHeight,
        });

        twoInstance.appendTo(divReference.current);

        setTwoInstance(twoInstance);

    },        []);

    const resizeEventListener = () => {
        if (!divReference.current) {
            return;
        }

        setClientWidth(divReference.current.clientWidth);
        logger.debug('clientWidth', divReference.current.clientWidth);

    };

    useEffect(() => {

        if (!divReference.current) {
            return;
        }

        setClientWidth(divReference.current.clientWidth);
        logger.debug('clientWidth', divReference.current.clientWidth);

        divReference.current.addEventListener('resize', resizeEventListener);

        return () => {

            if (!divReference.current) {
                return;
            }

            divReference.current.removeEventListener('resize', resizeEventListener);

        };

    },        [divReference.current]);

    useEffect(() => {

        if (!twoInstance) {
            return;
        }

        if (!divReference.current) {
            return;
        }

        twoInstance.clear();

        twoInstance.width = clientWidth;
        twoInstance.height = clientHeight;

        const upperViewWidth = clientWidth / 3;
        const multFactor = upperViewWidth / width;

        const widthPx = width * multFactor;
        const depthPx = depth * multFactor;
        const heightPx = height * multFactor;

        const heightAdd = 0.13 * depth;
        const heightAddPx = heightAdd * multFactor;

        const upperView = twoInstance.makeRectangle(clientWidth / 4, clientHeight / 2, widthPx, depthPx);
        upperView.fill = '#e0e0e0';
        upperView.stroke = '#424242';
        upperView.linewidth = 4;

        const backPost = twoInstance.makeLine(-widthPx / 2, (-heightPx / 2) - heightAddPx, -widthPx / 2, heightPx / 2);
        const frontPost = twoInstance.makeLine(widthPx / 2, -heightPx / 2, widthPx / 2, heightPx / 2);

        const traversePost = twoInstance.makeLine(
            (-widthPx / 2) - 2,
            (-heightPx / 2) - heightAddPx,
            (widthPx / 2) + 2,
            -heightPx / 2,
        );

        backPost.stroke = '#424242';
        backPost.linewidth = 4;
        frontPost.stroke = '#424242';
        frontPost.linewidth = 4;
        traversePost.stroke = '#424242';
        traversePost.linewidth = 4;

        let sideView = null;

        if (isStandalone) {
            sideView = twoInstance.makeGroup(backPost, frontPost, traversePost);
        } else {
            sideView = twoInstance.makeGroup(frontPost, traversePost);
        }

        sideView.translation.set((clientWidth / 4) * 3, clientHeight / 2, widthPx, heightPx);

        twoInstance.makeText(`${width.toFixed(2)}m`, clientWidth / 4, clientHeight / 2 + depthPx / 2 + 14);
        twoInstance.makeText(`${width.toFixed(2)}m`, clientWidth * 3 / 4, clientHeight / 2 + heightPx / 2 + 14);

        const heightBackText = twoInstance.makeText(
            `${(height + heightAdd).toFixed(2)}m`,
            (clientWidth / 4) * 3 - widthPx / 2 - 14,
            (clientHeight / 2) - heightAdd,
        );

        const heightFrontText = twoInstance.makeText(
            `${height.toFixed(2)}m`,
            (clientWidth / 4) * 3 + widthPx / 2 + 14,
            clientHeight / 2,
        );
        const depthText = twoInstance.makeText(
            `${depth.toFixed(2)}m`,
            (clientWidth / 4) - widthPx / 2 - 14,
            clientHeight / 2,
        );
        depthText.rotation = 3 * Math.PI / 2;
        heightBackText.rotation = 3 * Math.PI / 2;
        heightFrontText.rotation = 3 * Math.PI / 2;

        twoInstance.makeText('Grundriss', clientWidth / 4,  40);
        twoInstance.makeText('Seitenansicht', clientWidth * 3 / 4,  40);

        twoInstance.update();

    },        [width, height, depth, clientWidth]);

    return (
        <div
            className={s.DrawingRoot}
            ref={divReference}
            id={'drawing-root'}
        />
    );

};

export default Drawing;
