import React, { useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import s from './Content.css';
import WelcomeSite from '../Site/WelcomeSite/WelcomeSite';
import ConfigurationListSite from '../Site/ConfigurationListSite/ConfigurationListSite';
import ModifyConfigurationSite from '../Site/NewConfigurationSite/ModifyConfigurationSite';
import { DataContextProvider } from '../Context/DataContext';
import * as Logger from 'logplease';
import { UserContext } from '../Context/UserContext';
import AccountSite from '../Site/AccountSite/AccountSite';
import ConfigurationDetailSite from '../Site/ConfigurationDetailSite/ConfigurationDetailSite';
import SettingsSite from '../Site/SettingsSite/SettingsSite';
import LegalSite from '../Site/LegalSite/LegalSite';
import ResetPasswordSite from '../Site/ResetPasswordSite/ResetPasswordSite';

const logger = Logger.create('Content.tsx');

const UserRoutes = () => (
    <Switch>
        <Route
            path={'/configuration/new'}
            component={ModifyConfigurationSite}
        />
        <Route
            path={'/configuration/:itemId/edit'}
            component={ModifyConfigurationSite}
        />
        <Route
            path={'/configuration/:itemId'}
            component={ConfigurationDetailSite}
        />
        <Route
            path={'/configuration'}
            component={ConfigurationListSite}
        />
        <Route
            path={'/account'}
            component={AccountSite}
        />
        <Route
            path={'/settings'}
            component={SettingsSite}
        />
        <Route
            exact
            path={'/legal'}
            component={LegalSite}
        />
        <Route
            path={'*'}
            component={ConfigurationListSite}
        />
    </Switch>
);

const AnonymousRoutes = () => (
    <Switch>
        <Route
            path={'*'}
            component={ModifyConfigurationSite}
        />
    </Switch>
);

const Content = () => {

    logger.debug('Rendering userContext...');
    const userContext = useContext(UserContext);

    if (!userContext.isAuthenticated()) {
        return (
            <div className={s.Content}>
                <Switch>

                    <Route
                        exact
                        path={'/legal'}
                        component={LegalSite}
                    />
                    <Route
                        exact
                        path={'/resetPassword'}
                        component={ResetPasswordSite}
                    />
                    <Route
                        path={'/configuration/new'}
                        component={ModifyConfigurationSite}

                    />
                    <Route
                        exact
                        path={'*'}
                        component={WelcomeSite}
                    />
                </Switch>
            </div>
        );
    }

    if (!userContext.isAuthenticated()) {
        logger.debug('userContext is not authenticated.');
        return null;
    }

    return (
        <div className={s.Content}>
            <DataContextProvider>
                {
                    userContext.isAnonymous() ?
                        <AnonymousRoutes/>
                        : <UserRoutes/>
                }

            </DataContextProvider>
        </div>
    );
};

export default withRouter(Content);
