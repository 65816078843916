exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Site-ConfigurationSite-FinalSite__PersonalData--3PZnA {\n    margin-top: 1em;\n}\n", ""]);

// exports
exports.locals = {
	"PersonalData": "src-Site-ConfigurationSite-FinalSite__PersonalData--3PZnA"
};