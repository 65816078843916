exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Components-FreeItem-NewFreeItem__NewFreeItem--f8QsX {\n    display: grid;\n    grid-template-columns: 3fr 1fr auto;\n    grid-gap: 1em;\n\n    justify-content: center;\n    align-items: center;\n\n    padding-top: 1em;\n\n}\n", ""]);

// exports
exports.locals = {
	"NewFreeItem": "src-Components-FreeItem-NewFreeItem__NewFreeItem--f8QsX"
};