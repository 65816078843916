exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Components-Sidebar-Sidebar__Logo--298ld {\n    padding: 1em;\n    width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"Logo": "src-Components-Sidebar-Sidebar__Logo--298ld"
};