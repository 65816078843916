import {
    SnackbarProvider,
} from '@creatdevsolutions/notistack';
import React, { Component } from 'react';

type Props = {
    children: React.ReactNode,
};

class NotificationContainer extends Component<Props> {

    constructor(props: Props) {

        super(props);

    }

    render()    {

        return (
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                {this.props.children}
            </SnackbarProvider>
        );
    }

}

export {
    NotificationContainer,
};
