import { useTheme } from '@material-ui/styles';
import { unstable_useMediaQuery as useMediaQueryInternal } from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core';

import * as Logger from 'logplease';

// @ts-ignore
const logger = Logger.create('useMediaQuery.ts');

export enum BreakpointType {
    UP,
    DOWN,
}

export enum Breakpoint {
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
}

export function useMediaQuery(bType: BreakpointType, breakpoint: Breakpoint, theme?: Theme) {

    const themeToUse = theme || useTheme() as any;

    let isMatch;

    if (bType === BreakpointType.DOWN) {
        isMatch = useMediaQueryInternal(themeToUse.breakpoints.down(breakpoint), { noSsr: true } as any);
    } else {
        isMatch = useMediaQueryInternal(themeToUse.breakpoints.up(breakpoint), { noSsr: true } as any);
    }

    return isMatch;
}

export default useMediaQuery;
