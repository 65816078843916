import useRouter from '../../Hooks/useRouter';
import Logger from 'logplease';
import ButtonBar from '../../Components/ButtonBar/ButtonBar';
import { Button, TextField } from '@material-ui/core';
import s from './ResetPasswordSite.css';
import Connector from '../../Connector';
import React, { useState } from 'react';
import { useSnackbar } from '@creatdevsolutions/notistack';

const logger = Logger.create('ResetPasswordSite.tsx');

const ResetPasswordSite = () => {

    const routerContext = useRouter();
    const snackbarContext = useSnackbar();
    const routeParams: any = {};

    routerContext.location.search.substr(1).split('&').map((str) => {
        const [key, value] = str.split('=');
        routeParams[key] = value;
    });

    const [newPassword, setPassword] = useState('');
    const [newPasswordRepeat, setPasswordRepeat] = useState('');

    const onSubmit = () => {

        if (!newPassword || !newPasswordRepeat || newPassword !== newPasswordRepeat) {
            snackbarContext.enqueueSnackbar('Die Passwörter stimmen nicht überein', { variant: 'error' });
        }

        Connector.resetPassword(routeParams.id, routeParams.token, newPassword).then(() => {
            snackbarContext.enqueueSnackbar('Das Passwort wurde geändert', { variant: 'success' });
            routerContext.history.push('/');
        }).catch((e) => {
            snackbarContext.enqueueSnackbar('Das Passwort konnte nicht geändert werden', { variant: 'error' });
            logger.error(e);
        });

    };

    logger.debug('token', routeParams.token);
    logger.debug('id', routeParams.id);

    return (
        <div className={s.ResetPassword}>
            <ButtonBar
                title={'Passwort zurücksetzen'}
            >
                <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={onSubmit}
                >
                    Speichern
                </Button>
            </ButtonBar>
            <TextField
                fullWidth
                variant={'outlined'}
                margin={'normal'}
                type={'password'}
                label={'Passwort'}
                onChange={e => setPassword(e.target.value)}
                value={newPassword}
            />
            <TextField
                fullWidth
                variant={'outlined'}
                margin={'normal'}
                type={'password'}
                label={'Passwort wiederholen'}
                onChange={e => setPasswordRepeat(e.target.value)}
                value={newPasswordRepeat}
            />
        </div>
    );
};

export default ResetPasswordSite;
