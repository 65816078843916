import ConfigurationContext from '../../Context/ConfigurationContext';
import React, { Fragment , useContext } from 'react';

import * as Logger from 'logplease';
import {
    Divider, Icon,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { DataContext } from '../../Context/DataContext';
import useClientConfiguration from '../../Hooks/useClientConfiguration';
import { usePriceWithEditConfiguration } from '../../Hooks/usePrice';

import s from './PriceView.css';
import { FreeItemWithFakeId } from '../../Types/ConfigurationTypes';

// @ts-ignore
const logger = Logger.create('PriceView.tsx');

type ExternalProps = {
    isExtended?: boolean,
    isStandalone?: boolean,
    areFreeItemsRemovable?: boolean,
    onFreeItemRemove?: () => void,
};

type Props = ExternalProps;

const PriceView = (props: Props) => {

    const dataContext = useContext(DataContext);
    const configurationContext = useContext(ConfigurationContext);

    const {
        deckType,
        roofType,
        coverType,
        colorType,
        extraItems,
        freeItems,
        width,
        height,
        depth,
    } = useClientConfiguration(configurationContext, dataContext.decks);

    const onFreeItemRemove = (removedFreeItem: FreeItemWithFakeId) => {
        const [existingFreeItems, setFreeItems] = configurationContext.freeItems;
        setFreeItems(existingFreeItems.filter(fI => fI.fakeId !== removedFreeItem.fakeId));
        if (props.onFreeItemRemove) {
            props.onFreeItemRemove();
        }
    };

    const currentPrice = usePriceWithEditConfiguration(configurationContext);

    // WILO: Nutzer sieht Preis mit Rabatt, PDF hat aber keine
    // Das liegt daran, dass die applied* nicht upgedatet werden

    const currentDeckTitle = deckType &&
        `${deckType.name} (${width.toFixed(2)}m x ${depth.toFixed(2)}m x ${height.toFixed(2)}m)` ;

    const totalPrice = currentPrice.totalPrice - currentPrice.discountPrice;

    return (
        <List disablePadding>
            {
                !props.isStandalone ?
                    <ListItem disableGutters>
                        <ListItemText
                            primary={'Ihre Konfiguration'}
                            primaryTypographyProps={{ variant: 'h6' }}
                            secondary={`${totalPrice.toFixed(2)}€`}
                            secondaryTypographyProps={{ variant: 'button' }}
                        />
                    </ListItem>
                    : null
            }

            {
                props.isExtended && deckType ?
                    <ListItem disableGutters>
                        <ListItemText
                            primary={currentDeckTitle}
                            secondary={'Typ'}
                        />
                        <ListItemSecondaryAction>
                            <Typography variant={'button'}>
                                {currentPrice.deckType.toFixed(2)}€
                            </Typography>

                        </ListItemSecondaryAction>
                    </ListItem>

                    : null
            }
            {
                props.isExtended && roofType ?
                    <ListItem disableGutters>
                        <ListItemText
                            primary={roofType.name}
                            secondary={'Dach'}
                        />
                        <ListItemSecondaryAction>
                            <Typography variant={'button'}>
                                {currentPrice.roofType.toFixed(2)}€
                            </Typography>

                        </ListItemSecondaryAction>
                    </ListItem>
                    : null
            }
            {
                props.isExtended && colorType ?
                    <ListItem disableGutters>
                        <ListItemText
                            primary={colorType.name}
                            secondary={'Farbe'}
                        />
                        <ListItemSecondaryAction>
                            <Typography variant={'button'}>
                                {currentPrice.colorType.toFixed(2)}€
                            </Typography>

                        </ListItemSecondaryAction>
                    </ListItem>
                    : null
            }
            {
                props.isExtended && coverType ?
                    <ListItem disableGutters>
                        <ListItemText
                            primary={coverType.name}
                            secondary={'Blende'}
                        />
                        <ListItemSecondaryAction>
                            <Typography variant={'button'}>
                                {currentPrice.coverType.toFixed(2)}€
                            </Typography>

                        </ListItemSecondaryAction>
                    </ListItem>
                    : null
            }
            {
                props.isExtended && extraItems.map((extraItem) => {

                    const priceForExtraItem = currentPrice.extraItems
                        .find(eI => eI.itemId === extraItem.id)!;

                    if (!priceForExtraItem) {
                        return null;
                    }

                    return (
                        <ListItem disableGutters>
                            <ListItemText
                                primary={extraItem.name}
                                secondary={'Zubehör'}
                            />
                            <ListItemSecondaryAction>
                                <Typography variant={'button'}>
                                    {priceForExtraItem.totalPrice.toFixed(2)}€
                                </Typography>

                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })
            }
            {
                props.isExtended && freeItems.map((freeItem) => {

                    const priceElement = currentPrice.freeItems
                        .find(priceFreeItem => priceFreeItem.fakeId === freeItem.fakeId);

                    if (!priceElement) {
                        return null;
                    }

                    return (
                        <ListItem disableGutters>
                            <ListItemText
                                primary={priceElement.itemName}
                                secondary={'Dynamische Teile'}
                            />
                            <div className={s.SecondaryAction}>
                                <Typography variant={'button'}>
                                    {priceElement.totalPrice.toFixed(2)}€
                                </Typography>
                                {
                                    props.areFreeItemsRemovable
                                        ? <IconButton onClick={onFreeItemRemove.bind(null, freeItem)}>
                                            <Icon>close</Icon>
                                        </IconButton>
                                        : null
                                }
                            </div>
                        </ListItem>
                    );
                })
            }
            {
                props.isExtended && currentPrice.discountPrice
                    ? <ListItem disableGutters>
                        <ListItemText
                            primary={`Rabatt (${currentPrice.discountPercent}%)`}
                        />
                        <ListItemSecondaryAction>
                            <Typography variant={'button'}>
                                -{currentPrice.discountPrice.toFixed(2)}€
                            </Typography>

                        </ListItemSecondaryAction>
                    </ListItem>
                    : null
            }
            {
                props.isStandalone ?
                    <Fragment>
                        <Divider/>
                        <ListItem disableGutters>
                            <ListItemText
                                primary={'Gesamtpreis'}
                                secondary={'ohne Mehrwertsteuer'}
                            />
                            <ListItemSecondaryAction>
                                <Typography variant={'button'}>
                                    {(totalPrice).toFixed(2)}€
                                </Typography>

                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemText
                                primary={'Gesamtpreis'}
                                secondary={'mit Mehrwertsteuer'}
                            />
                            <ListItemSecondaryAction>
                                <Typography variant={'button'}>
                                    {(totalPrice * 1.19).toFixed(2)}€
                                </Typography>

                            </ListItemSecondaryAction>
                        </ListItem>
                    </Fragment>
                    : null
            }

        </List>
    );
};

export default PriceView;
