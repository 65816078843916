import React from 'react';
import { StateEntry } from './Types';

export type AccountContextType = {
    phoneNumber: StateEntry<string>
    eMail: StateEntry<string>
    password: StateEntry<string>,
};

const generateStateEntryWithEmptyChangeFunction = <T>(value: T): StateEntry<T> => {
    return [
        value,
        () => {

        },
    ];
};

const generateAccountDefaultContext: () => AccountContextType = () => {
    return {
        phoneNumber: generateStateEntryWithEmptyChangeFunction<string>(''),
        password: generateStateEntryWithEmptyChangeFunction<string>(''),
        eMail: generateStateEntryWithEmptyChangeFunction<string>(''),
    };
};

const defaultAccountContext = generateAccountDefaultContext();

const AccountContext = React.createContext<AccountContextType>(defaultAccountContext);

export default AccountContext;

export {
    generateAccountDefaultContext,
};
