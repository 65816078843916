import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import useServerConfiguration from '../../Hooks/useServerConfiguration';
import { DataContext } from '../../Context/DataContext';
import useMediaQuery, { Breakpoint, BreakpointType } from '../../Hooks/useMediaQuery';
import { UserContext } from '../../Context/UserContext';
import useRouter from '../../Hooks/useRouter';
import { useSnackbar } from '@creatdevsolutions/notistack';
import ButtonBar from '../../Components/ButtonBar/ButtonBar';
import { ServerDeckConfiguration } from '../../Types/ConfigurationTypes';
import { AccountTypes } from '../../Types/UserTypes';
import { usePriceWithServerConfiguration } from '../../Hooks/usePrice';

enum STATUS {
    SAVED = 'Gespeichert',
    OFFER_REQUESTED = 'Angebot angefordert',
    OFFER_CONFIRMED = 'Angebot bestätigt',
    ORDERED = 'Bestellt',
}

const ConfigurationListSite = () => {

    const userContext = useContext(UserContext);
    const routerContext = useRouter();
    const snackbarContext = useSnackbar();

    const [userConfigurations, setUserConfigurations] = useState<ServerDeckConfiguration[]>([]);

    useEffect(() => {
        const containerInstance = userContext.getInstance();
        containerInstance.getConfigurations().then((configurations) => {
            setUserConfigurations(configurations);
        }).catch(() => {
            snackbarContext.enqueueSnackbar('Konfigurationen konnten nicht geladen werden.', { variant: 'error' });
        });
    },        []);

    const dataContext = useContext(DataContext);
    const isMobile = useMediaQuery(BreakpointType.DOWN, Breakpoint.SM);
    const isAdmin = userContext.isAdmin();
    return (
        <div>
            <ButtonBar title={'Konfigurationen'}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => {
                        routerContext.history.push('/configuration/new');
                    }}
                >
                    Hinzufügen
                </Button>
            </ButtonBar>
            <div>
                <Table
                    padding={'checkbox'}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Modell</TableCell>
                            <TableCell>Maße</TableCell>
                            {
                                !isMobile ?
                                    <Fragment>
                                        <TableCell>Dach</TableCell>
                                        <TableCell>Farbe</TableCell>
                                    </Fragment>
                                    : null
                            }

                            {
                                isAdmin ?
                                    <TableCell>User</TableCell>
                                    : null
                            }
                            <TableCell>Preis</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            userConfigurations.map((uC) => {

                                const u = useServerConfiguration(uC, dataContext.decks);
                                const currentPrice = usePriceWithServerConfiguration(uC);
                                const mesaurements =
                                    `${u.width.toFixed(2)}m x ${u.depth.toFixed(2)}m x ${u.height.toFixed(2)}m`;

                                return (
                                    <TableRow
                                        key={u.id}
                                        hover
                                        onClick={() => routerContext.history.push(`/configuration/${u.id}`)}
                                    >
                                        <TableCell>{u.id}</TableCell>
                                        <TableCell>{u.deckType && u.deckType.name}</TableCell>
                                        <TableCell>{mesaurements}</TableCell>
                                        {
                                            !isMobile ?
                                                <Fragment>
                                                    <TableCell>{u.roofType && u.roofType.name}</TableCell>
                                                    <TableCell>{u.colorType && u.colorType.name}</TableCell>
                                                </Fragment>
                                                : null
                                        }
                                        {
                                            isAdmin ?
                                                <TableCell>
                                                    {uC.user.accountType === AccountTypes.ANONYMOUS
                                                        ? 'Anonymer Nutzer'
                                                        : uC.user.eMail}
                                                </TableCell>
                                                : null

                                        }
                                        <TableCell>{`${(currentPrice.totalPrice * 1.19).toFixed(2)} €`}</TableCell>
                                        <TableCell>
                                            {u.configurationStatus ? STATUS[u.configurationStatus] : ''}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }

                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default ConfigurationListSite;
