import { Button, TextField } from '@material-ui/core';
import s from './NewFreeItem.css';
import React, { useContext, useState } from 'react';
import ConfigurationContext from '../../Context/ConfigurationContext';

type NewFreeItemType = {
    fakeId: string
    name: string,
    unitPrice: number,
};

type NewFreeItemProps = {
    onNewItem: (newItem: NewFreeItemType) => void,
};

const NewFreeItem = (props: NewFreeItemProps) => {

    const [name, setName] = useState<string>('');
    const [price, setPrice] = useState<number>(0);

    const configurationContext = useContext(ConfigurationContext);

    const onButtonClick = () => {

        const newItemId = Math.random().toString(36).substr(2, 8);

        const newItem: NewFreeItemType = {
            name,
            fakeId: newItemId,
            unitPrice: price,
        };

        const [existingFreeItems, setFreeItems] = configurationContext.freeItems;

        const newFreeItems = [
            ...existingFreeItems,
            newItem,
        ];

        setFreeItems(newFreeItems);

        props.onNewItem(newItem);
        setName('');
        setPrice(0);

    };

    return (
        <div className={s.NewFreeItem}>
            <TextField
                variant={'outlined'}
                fullWidth
                label={'Name'}
                value={name}
                onChange={e => setName(e.target.value)}
            />
            <TextField
                type={'number'}
                variant={'outlined'}
                fullWidth
                label={'Preis'}
                value={price}
                onChange={e => setPrice(Number(e.target.value))}
            />
            <Button
                variant={'contained'}
                color={'primary'}
                onClick={onButtonClick}
            >
                Speichern
            </Button>
        </div>
    );
};

export default NewFreeItem;
