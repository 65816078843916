import { AppBar, Chip, Icon, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ExitToApp, Menu } from '@material-ui/icons';

import React, { Fragment } from 'react';

import s from './Header.css';
import { AccountTypes, User } from '../Types/UserTypes';

type HeaderProps = {
    user: User | null
    onClick: (e: any) => void
    onLogout: (e: any) => void
    showMenuButton?: boolean,
    showLogoutButton?: boolean,
};

export default (props: HeaderProps) => {

    return (
        <AppBar position={'relative'}>
            <Toolbar className={s.Toolbar}>
                <div className={s.Container}>
                    {
                        props.showMenuButton ?
                            <IconButton
                                onClick={props.onClick}
                                color="inherit"
                            >
                                <Menu/>
                            </IconButton>
                            : null
                    }

                    <Typography variant="h6" color="inherit">
                        Traumterrassen - Konfigurator
                    </Typography>
                </div>
                <div className={s.AccountBar}>
                    {
                        props.user
                            ? <Fragment>
                                {
                                    props.user.accountType === AccountTypes.ADMIN ?
                                        <Chip
                                            color={'secondary'}
                                            label={'Administrator'}
                                        /> : null
                                }
                                <Icon>
                                    account_circle
                                </Icon>
                            </Fragment>
                            : null

                    }

                    {
                        props.showLogoutButton ?
                            <IconButton
                                color={'inherit'}
                                onClick={props.onLogout}
                            >
                                <ExitToApp/>
                            </IconButton>
                            : null
                    }
                </div>

            </Toolbar>
        </AppBar>
    );

};
