import React, { useContext, useEffect, useState } from 'react';
import {
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText, Radio,
    Switch,
} from '@material-ui/core';
import ButtonBar from '../../Components/ButtonBar/ButtonBar';
import { AddressForm, AddressFormContexts } from '../../Components/UserForms/UserForms';
import StepContext from '../../Context/StepContext';
import FinalizeContext from '../../Context/FinalizeContext';

const AddressSite = () => {

    const stepContext = useContext(StepContext);
    const finalizeContext = useContext(FinalizeContext);
    const [isBillingAddressFilled, setBillingAddressFilled] = useState<boolean>(false);
    const [isShippingAddressFilled, setShippingAddressFilled] = useState<boolean>(false);

    const [
        shouldUseBillingAddressAsShippingAddress,
        setUseBillingAddressAsShippingAddress,
    ] = finalizeContext.useBillingAddressAsShippingAddress;

    useEffect(() => {

        const isCompleted = isBillingAddressFilled && isShippingAddressFilled
            || isBillingAddressFilled && shouldUseBillingAddressAsShippingAddress;

        stepContext.isCompleted[stepContext.currentStep][1](isCompleted);
    },        [isBillingAddressFilled, isShippingAddressFilled, shouldUseBillingAddressAsShippingAddress]);

    return (
        <Grid container spacing={16}>
            <Grid
                item
                xs={12}
                lg={6}
            >
                <ButtonBar title={'Lieferung'}/>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={'Standardversand'}
                            secondary={'Speditionsversand in Deutschland'}
                        />
                        <ListItemSecondaryAction>
                            <Radio color={'primary'} checked/>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>

            </Grid>
            <Grid
                item
                xs={12}
                lg={6}
            >
                <ButtonBar title={'Rechnungsadresse'}/>
                <AddressForm
                    contextToUse={AddressFormContexts.BillingAddress}
                    onCompletedChange={isFilled => setBillingAddressFilled(isFilled)}
                />
                <ButtonBar hasMarginTop title={'Lieferadresse'}>
                    <FormControlLabel
                        control={
                            <Switch
                                color={'primary'}
                                checked={shouldUseBillingAddressAsShippingAddress}
                                onChange={(_, isChecked) => setUseBillingAddressAsShippingAddress(isChecked)}
                            />
                        }
                        label="Rechnungsadresse benutzen"
                    />

                </ButtonBar>
                <AddressForm
                    disabled={shouldUseBillingAddressAsShippingAddress}
                    contextToUse={AddressFormContexts.ShippingAddress}
                    onCompletedChange={isFilled => setShippingAddressFilled(isFilled)}
                />

            </Grid>
        </Grid>
    );

};

export default AddressSite;
