exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Site-ResetPasswordSite-ResetPasswordSite__ResetPassword--3mgAf {\n    display: flex;\n    justify-content: center;\n    flex-flow: column;\n    max-width: 600px;\n\n    margin: auto;\n}\n", ""]);

// exports
exports.locals = {
	"ResetPassword": "src-Site-ResetPasswordSite-ResetPasswordSite__ResetPassword--3mgAf"
};