import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Icon,
    MobileStepper as MaterialMobileStepper,
    Step,
    StepButton,
    Stepper,
    Typography,
} from '@material-ui/core';

import s from './ConfigurationSite.css';
import Measurement from './Measurement';
import StepContext, { StepState } from '../../Context/StepContext';
import ConfigurationContext from '../../Context/ConfigurationContext';
import Roofing from './Roofing';
import Type from './Type';
import Color from './Color';
import PriceView from './PriceView';
import IconButton from '@material-ui/core/es/IconButton';
import Covers from './Covers';
import ExtraItems from './ExtraItems';

import * as Logger from 'logplease';
import FinalSite from './FinalSite';
import FinalizeContext, { FinalizeContextType } from '../../Context/FinalizeContext';
import { UpdateUserType } from '../../Types/UserTypes';
import useMediaQuery, { Breakpoint, BreakpointType } from '../../Hooks/useMediaQuery';
import * as classNames from 'classnames';
import { UserContext } from '../../Context/UserContext';
import useRouter from '../../Hooks/useRouter';
import { useSnackbar } from '@creatdevsolutions/notistack';
import AccountContext, { AccountContextType } from '../../Context/AccountContext';
import {
    ConfigurationStatus,
    EditDeckConfiguration,
    ExtraItem,
    FreeItemWithFakeId,
} from '../../Types/ConfigurationTypes';
import { AddressContextType, BillingAddressContext, ShippingAddressContext } from '../../Context/AddressContext';
import AddressSite from './AddressSite';

const logger = Logger.create('ConfigurationSite.tsx');

type ExternalProps = {
    configurationId?: number,
};

type StepType = {
    name: string,
    renderFunction?: () => JSX.Element,
    noHeadline?: boolean,
};

const configurationSteps: StepType[] = [
    {
        name: 'Typ',
        renderFunction: () => (
            <Type/>
        ),
    },
    {
        name: 'Maße',
        renderFunction: () => (
            <Measurement/>
        ),

    },
    {
        name: 'Dach',
        renderFunction: () => (
            <Roofing/>
        ),
    },
    {
        name: 'Farbe',
        renderFunction: () => (
            <Color/>
        ),
    },
    {
        name: 'Blenden',
        renderFunction: () => (
            <Covers/>
        ),
    },
    {
        name: 'Extras',
        renderFunction: () => (
            <ExtraItems/>
        ),
    },
    {
        name: 'Lieferung',
        renderFunction: () => (
            <AddressSite/>
        ),
        noHeadline: true,
    },
    {
        name: 'Abschluss',
        renderFunction: () => (
            <FinalSite/>
        ),
    },
];

const DesktopStepper = () => {

    const stepContext = useContext(StepContext);

    return (
        <Stepper nonLinear alternativeLabel>
            {configurationSteps.map((cStep, index) => {

                const [isCompleted] = stepContext.isCompleted[index];
                const [isLastCompleted] = stepContext.isCompleted[index - 1] || [true];
                const isClickable = index > 0 ? isLastCompleted : true;

                const isActive = index === stepContext.currentStep;

                return (
                    <Step
                        key={index}
                        active={isActive}
                        completed={isCompleted && !isActive}

                    >
                        <StepButton
                            disabled={!isClickable}
                            onClick={() => {
                                stepContext.setCurrentStep(index);
                            }}
                        >
                            {cStep.name}
                        </StepButton>
                    </Step>
                );
            })}
        </Stepper>
    );
};

type MobileStepperProps = {
    isNextStepDisabled: boolean,
};

const MobileStepper = (props: MobileStepperProps) => {

    const stepContext = useContext(StepContext);

    const handleClick = (diffNumber: number) => () => {
        stepContext.setCurrentStep(stepContext.currentStep + diffNumber);
    };

    return (
        <MaterialMobileStepper
            steps={stepContext.maxSteps + 1}
            position="static"
            activeStep={stepContext.currentStep}
            nextButton={
                <Button
                    size="small"
                    onClick={handleClick(1)}
                    disabled={stepContext.isLastStep || props.isNextStepDisabled}
                >
                    Weiter
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleClick(-1)} disabled={stepContext.currentStep === 0}>
                    Zurück
                </Button>
            }
        />
    );
};

const ConfigurationSite = (props: ExternalProps) => {

    // Getting Theming Details

    const isMobile = useMediaQuery(BreakpointType.DOWN, Breakpoint.SM);

    const userContext = useContext(UserContext);
    const routerContext = useRouter();
    const snackbarContext = useSnackbar();

    // Initializing UI State

    const [isExtended, setExtended] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    // Initializing Steps

    const maxSteps = configurationSteps.length - 1;
    const [currentStep, setCurrentStep] = useState<number>(props.configurationId ? maxSteps : 0);
    const isLastStep = currentStep === maxSteps;
    const stepStates: StepState = {
        currentStep,
        setCurrentStep,
        maxSteps,
        isLastStep,
        isCompleted: configurationSteps.map(() => {
            return useState(!!props.configurationId);
        }),
    };

    // Initalize AccountContext

    const finalizeContext: FinalizeContextType = {
        createUser: useState(true),
        sendOffer: useState(true),
        useBillingAddressAsShippingAddress: useState(true),
    };

    const shippingAddressContext: AddressContextType = {
        firstName: useState<string>(''),
        lastName: useState<string>(''),
        streetName: useState<string>(''),
        streetNumber: useState<string>(''),
        zipCode: useState<string>(''),
        city: useState<string>(''),
    };
    const billingAddressContext: AddressContextType = {
        firstName: useState<string>(''),
        lastName: useState<string>(''),
        streetName: useState<string>(''),
        streetNumber: useState<string>(''),
        zipCode: useState<string>(''),
        city: useState<string>(''),
    };
    const accountContext: AccountContextType = {
        phoneNumber: useState<string>(''),
        eMail: useState<string>(''),
        password: useState<string>(''),
    };

    // Initialize Configuration

    const configurationState: EditDeckConfiguration = {
        id: props.configurationId,
        configurationStatus: useState<ConfigurationStatus | null>(null),
        height: useState<number>(2),
        width: useState<number>(4),
        depth: useState<number>(2.5),
        roofType: useState<string | null>(null),
        deckType: useState<string | null>(null),
        colorType: useState<string | null>(null),
        coverType: useState<string | null>(null),
        extraItems: useState<ExtraItem[]>([]),
        freeItems: useState<FreeItemWithFakeId[]>([]),
    };

    useEffect(() => {

        if (!props.configurationId) {
            return;
        }

        userContext.getInstance().getConfiguration(props.configurationId).then((c) => {

            // Set Addresses

            billingAddressContext.firstName[1](c.billingAddress.firstName);
            billingAddressContext.lastName[1](c.billingAddress.lastName);
            billingAddressContext.streetName[1](c.billingAddress.streetName);
            billingAddressContext.streetNumber[1](c.billingAddress.streetNumber);
            billingAddressContext.zipCode[1](c.billingAddress.zipCode);
            billingAddressContext.city[1](c.billingAddress.city);

            shippingAddressContext.firstName[1](c.shippingAddress.firstName);
            shippingAddressContext.lastName[1](c.shippingAddress.lastName);
            shippingAddressContext.streetName[1](c.shippingAddress.streetName);
            shippingAddressContext.streetNumber[1](c.shippingAddress.streetNumber);
            shippingAddressContext.zipCode[1](c.shippingAddress.zipCode);
            shippingAddressContext.city[1](c.shippingAddress.city);

            const useBillingAddressAsShippingAddress =
                c.billingAddress.firstName === c.shippingAddress.firstName &&
                c.billingAddress.lastName === c.shippingAddress.lastName &&
                c.billingAddress.streetName === c.shippingAddress.streetName &&
                c.billingAddress.streetNumber === c.shippingAddress.streetNumber &&
                c.billingAddress.zipCode === c.shippingAddress.zipCode &&
                c.billingAddress.city === c.shippingAddress.city;

            finalizeContext.useBillingAddressAsShippingAddress[1](useBillingAddressAsShippingAddress);

            const mappedFreeItems = c.freeItems.map((fI) => {
                return {
                    ...fI,
                    fakeId: Math.random().toString(36).substr(2, 8),
                };
            });

            configurationState.configurationStatus[1](c.configurationStatus);
            configurationState.height[1](c.height);
            configurationState.width[1](c.width);
            configurationState.depth[1](c.depth);
            configurationState.roofType[1](c.roofType);
            configurationState.deckType[1](c.deckType);
            configurationState.colorType[1](c.colorType);
            configurationState.coverType[1](c.coverType);
            configurationState.extraItems[1](c.extraItems);
            configurationState.freeItems[1](mappedFreeItems);

        }).catch(() => {
            snackbarContext.enqueueSnackbar('Konfiguration konnte nicht geladen werden.', { variant: 'error' });
        });

    },        []);

    // Define different variables for rendering.

    const currentConfigurationStep = configurationSteps[currentStep];

    if (!currentConfigurationStep) {
        throw Error('Wrong Index.');
    }

    // Functions

    const handleButtonClick = async () => {

        if (!isLastStep) {
            setCurrentStep(currentStep + 1);
            return;
        }

        setLoading(true);

        try {
            const shouldUseBillingAddressAsShippingAddress = finalizeContext.useBillingAddressAsShippingAddress[0];

            const billingAddress = {
                firstName: billingAddressContext.firstName[0],
                lastName: billingAddressContext.lastName[0],
                streetName: billingAddressContext.streetName[0],
                streetNumber: billingAddressContext.streetNumber[0],
                zipCode: billingAddressContext.zipCode[0],
                city: billingAddressContext.city[0],
            };

            const shippingAddress = shouldUseBillingAddressAsShippingAddress
                ? billingAddress
                : {
                    firstName: shippingAddressContext.firstName[0],
                    lastName: shippingAddressContext.lastName[0],
                    streetName: shippingAddressContext.streetName[0],
                    streetNumber: shippingAddressContext.streetNumber[0],
                    zipCode: shippingAddressContext.zipCode[0],
                    city: shippingAddressContext.city[0],
                };

            const connectorInstance = userContext.getInstance();

            if (userContext.isAnonymous()) {
                logger.info('Updating User on Server');

                const userParam: UpdateUserType = {
                    phoneNumber: accountContext.phoneNumber[0],
                    eMail: accountContext.eMail[0],
                };

                if (finalizeContext.createUser[0]) {
                    userParam.password = accountContext.password[0];
                }

                await connectorInstance.updateUserOnServer(userParam);

                if (finalizeContext.createUser) {
                    await userContext.updateUser();
                } else {
                    await userContext.logoutUser();
                }
            }

            let newConfiguration = null;

            if (configurationState.id) {
                logger.info('Trying to update a configuration on the server.');
                newConfiguration = await connectorInstance.updateConfigurationOnServer(
                    configurationState,
                    billingAddress,
                    shippingAddress,
                );
            } else {
                logger.info('Trying to create a configuration on the server.');
                newConfiguration = await connectorInstance.createConfigurationOnServer(
                    configurationState,
                    billingAddress,
                    shippingAddress,
                );
            }

            const newConfigurationId = newConfiguration.id!;

            if (!configurationState.id && finalizeContext.sendOffer[0]) {
                await connectorInstance.requestOfferOnServer(newConfigurationId);
            }

            snackbarContext.enqueueSnackbar('Die Konfiguration wurde gespeichert.', { variant: 'success' });
            routerContext.history.push(finalizeContext.createUser ? `/configuration/${newConfigurationId}` : '/');

        } catch (e) {
            logger.error(e);
            snackbarContext.enqueueSnackbar('Die Konfiguration konnte nicht gespeichert werden.', { variant: 'error' });
        }

    };

    const configurationStepperClasses = classNames({
        [s.ConfigurationStepper]: true,
        [s.ConfigurationStepperMobile]: isMobile,
    });

    const configurationOverviewClasses = classNames({
        [s.ConfigurationOverview]: true,
        [s.ConfigurationOverviewMobile]: isMobile,
    });

    const isNextStepDisabled = !stepStates.isCompleted[currentStep][0] ||
        isLastStep && isLoading;

    return (
        <div className={s.ConfigurationSite}>
            <ConfigurationContext.Provider
                value={configurationState}
            >
                <BillingAddressContext.Provider
                    value={billingAddressContext}
                >
                    <ShippingAddressContext.Provider
                        value={shippingAddressContext}
                    >
                        <AccountContext.Provider
                            value={accountContext}
                        >
                            <FinalizeContext.Provider
                                value={finalizeContext}
                            >
                                <StepContext.Provider
                                    value={stepStates}
                                >
                                    <div className={configurationStepperClasses}>
                                        {
                                            isMobile
                                                ? <MobileStepper
                                                    isNextStepDisabled={isNextStepDisabled}
                                                />
                                                : <DesktopStepper/>
                                        }
                                    </div>

                                    <div className={s.ConfigurationContent}>
                                        {
                                            !currentConfigurationStep.noHeadline ?
                                                <Typography variant={'h6'} paragraph>
                                                    {currentConfigurationStep.name}
                                                </Typography>
                                                : null
                                        }

                                        {currentConfigurationStep.renderFunction ?
                                            currentConfigurationStep.renderFunction()
                                            : null}

                                    </div>
                                    <div className={configurationOverviewClasses}>
                                        <div className={s.PriceViewWithExtender}>
                                            <IconButton
                                                onClick={() => setExtended(!isExtended)}
                                            >
                                                <Icon>
                                                    {isExtended ? 'expand_more' : 'expand_less'}
                                                </Icon>
                                            </IconButton>

                                            <PriceView
                                                isExtended={isExtended}
                                            />
                                        </div>
                                        {
                                            !isMobile ?
                                                <Button
                                                    className={s.NextButton}
                                                    variant={'contained'}
                                                    color={'primary'}
                                                    disabled={
                                                        isNextStepDisabled
                                                    }
                                                    onClick={handleButtonClick}
                                                >
                                                    {!isLastStep ? 'Weiter' : 'Speichern'}
                                                </Button>
                                                : null
                                        }

                                    </div>
                                </StepContext.Provider>
                            </FinalizeContext.Provider>
                        </AccountContext.Provider>
                    </ShippingAddressContext.Provider>
                </BillingAddressContext.Provider>
            </ConfigurationContext.Provider>
        </div>
    );
};

export default ConfigurationSite;
