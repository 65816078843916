import React from 'react';
import { StateEntry } from './Types';

export type AddressContextType = {
    firstName: StateEntry<string>
    lastName: StateEntry<string>
    streetName: StateEntry<string>,
    streetNumber: StateEntry<string>,
    zipCode: StateEntry<string>,
    city: StateEntry<string>,
};

const generateStateEntryWithEmptyChangeFunction = <T>(value: T): StateEntry<T> => {
    return [
        value,
        () => {

        },
    ];
};

const generateAccountDefaultContext: () => AddressContextType = () => {
    return {
        firstName: generateStateEntryWithEmptyChangeFunction<string>(''),
        lastName: generateStateEntryWithEmptyChangeFunction<string>(''),
        streetName: generateStateEntryWithEmptyChangeFunction<string>(''),
        streetNumber: generateStateEntryWithEmptyChangeFunction<string>(''),
        zipCode: generateStateEntryWithEmptyChangeFunction<string>(''),
        city: generateStateEntryWithEmptyChangeFunction<string>(''),
    };
};

const defaultAccountContext = generateAccountDefaultContext();

const ShippingAddressContext = React.createContext<AddressContextType>(defaultAccountContext);
const BillingAddressContext = React.createContext<AddressContextType>(defaultAccountContext);

export {
    ShippingAddressContext,
    BillingAddressContext,
};
