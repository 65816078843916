exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Site-ConfigurationSite-PriceView__SecondaryAction--30qzS {\n    display: flex;\n    align-items: center;\n}\n\n.src-Site-ConfigurationSite-PriceView__SecondaryAction--30qzS > * {\n    margin-left: 0.5em;\n}\n", ""]);

// exports
exports.locals = {
	"SecondaryAction": "src-Site-ConfigurationSite-PriceView__SecondaryAction--30qzS"
};