import React, { Dispatch, SetStateAction } from 'react';
import { StateEntry } from './Types';

export type StepState = {
    currentStep: number,
    setCurrentStep: Dispatch<SetStateAction<number>>
    maxSteps: number,
    isLastStep: boolean,
    isCompleted: StateEntry<boolean>[],

};

const defaultStepContext: StepState = {
    currentStep: 0,
    setCurrentStep: () => {},
    maxSteps: 0,
    isLastStep: true,
    isCompleted: [],
};

const StepContext = React.createContext<StepState>(defaultStepContext);

export default StepContext;
