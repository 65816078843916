import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Switch, Typography } from '@material-ui/core';
import PriceView from './PriceView';
import React, { Fragment, useContext, useEffect } from 'react';

import FinalizeContext from '../../Context/FinalizeContext';
import { UserContext } from '../../Context/UserContext';
import { UserDetailsForm } from '../../Components/UserForms/UserForms';

import s from './FinalSite.css';
import ConfigurationContext from '../../Context/ConfigurationContext';
import StepContext from '../../Context/StepContext';

const UserSelectionOffer = () => {

    const finalizeContext = useContext(FinalizeContext);
    const [sendOffer, setSendOffer] = finalizeContext.sendOffer;

    return (
        <List disablePadding>
            <ListItem disableGutters>
                <ListItemText
                    primary={'Zusenden eines verbindlichen Angebots'}
                    secondary={'Nach Abschluss der Konfiguration schicken wir Ihnen ein persönliches Angebot zu, ' +
                    'welches Sie anschließend bei uns in Auftrag geben können.'}
                />
                <ListItemSecondaryAction>
                    <Switch
                        color={'primary'}
                        checked={sendOffer}
                        onChange={(e: any) => setSendOffer(e.target.checked)}
                    />
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    );
};

const UserSelectionAccount = () => {

    const finalizeContext = useContext(FinalizeContext);
    const [createUser, setCreateUser] = finalizeContext.createUser;

    return (
        <List disablePadding>
            <ListItem disableGutters>
                <ListItemText
                    primary={'Anlegen eines Accounts'}
                    secondary={'Nach Abschluss der Konfiguration erstellen wir einen Nutzeraccount für Sie,' +
                    ' wo sie den Status ihrer Bestellung einsehen' +
                    ' und weitere Konfigurationen erstellen können.'}
                />
                <ListItemSecondaryAction>
                    <Switch
                        color={'primary'}
                        checked={createUser}
                        onChange={(e: any) => setCreateUser(e.target.checked)}
                    />
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    );
};

const FinalSite = () => {

    const userContext = useContext(UserContext);
    const configurationContext = useContext(ConfigurationContext);
    const stepContext = useContext(StepContext);

    useEffect(() => {
        if (!userContext.isAnonymous()) {
            const [, setCompleted] = stepContext.isCompleted[stepContext.currentStep];
            setCompleted(true);
        }
    },        []);

    return (
        <div>

            <Typography paragraph>
                {
                    configurationContext.id
                        ? 'Sie können ihre Konfiguration weiter bearbeiten,' +
                        ' indem Sie die einzelnen Kategorien direkt anspringen. Nach erfolgreicher Bearbeitung ' +
                        'können Sie ein verbindliches Angebot für ihre Konfiguration anfordern.'
                        : 'Sie haben Ihre Konfiguration abgeschlossen. Sie können nun entweder ein verbindliches' +
                        ' Angebot für Ihre Konfiguration von uns anfordern oder Ihre Konfiguration ' +
                        'mit einem Nutzeraccount verknüpfen, um es zu einem späteren Zeitpunkt weiter zu bearbeiten.'
                }

            </Typography>

            <PriceView
                isExtended
                isStandalone
            />
            {
                userContext.isAnonymous() ?
                    <Fragment>
                        <Divider/>
                        <UserSelectionAccount/>
                        <Divider/>
                        <Typography
                            variant={'body2'}
                            paragraph
                            className={s.PersonalData}
                        >
                            Mit der Eingabe ihrer persönlichen Daten geben Sie uns die Möglichkeit ihnen ein
                            personalisiertes Angebot zu schicken.
                            Natürlich werden hier die aktuellen Datenschutzbestimmungen erfüllt und alle ihre Daten
                            verschlüsselt übertragen.

                        </Typography>
                        <UserDetailsForm
                            onChange={(phoneNumber: string, eMail: string) => {
                                const [, setCompleted] = stepContext.isCompleted[stepContext.currentStep];
                                setCompleted(!!phoneNumber && !!eMail);
                            }}
                        />
                    </Fragment>
                    : null
            }
            {
                !configurationContext.id ?
                    <Fragment>
                        <Divider className={s.PersonalData}/>
                        <UserSelectionOffer
                        />
                    </Fragment>
                    : null
            }

        </div>
    );

};

export default FinalSite;
