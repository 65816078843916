import * as React from 'react';
import { Typography } from '@material-ui/core';

import s from './ButtonBar.css';
import classNames from 'classnames';

type Props = {
    title?: string,
    children?: React.ReactNode,
    hasMarginTop?: boolean,
};

const ButtonBar = (props: Props) => {

    const buttonBarClasses = classNames({
        [s.ButtonBar]: true,
        [s.ButtonBarMarginTop]: props.hasMarginTop,
    });

    return (
        <div className={buttonBarClasses}>
            <Typography variant={'h6'}>
                {props.title}
            </Typography>
            <div className={s.Buttons}>
                {props.children}
            </div>

        </div>
    );

};

export default ButtonBar;
