exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Site-ConfigurationDetailSite-ConfigurationDetailSite__SubHeader--3thGL {\n    margin-top: 1em\n}\n", ""]);

// exports
exports.locals = {
	"SubHeader": "src-Site-ConfigurationDetailSite-ConfigurationDetailSite__SubHeader--3thGL"
};