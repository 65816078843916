import { Decks } from '../Types/DeckTypes';

import Logger from 'logplease';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './UserContext';
import { useSnackbar } from '@creatdevsolutions/notistack';

// @ts-ignore
const logger = Logger.create('DataContext.ts');

type DataContextState = {
    decks: Decks,
};

type Props = {
    children: JSX.Element,
};

const defaultState: DataContextState = {
    decks: [],
};

const DataContext = React.createContext<DataContextState>(defaultState);
const DCProvider = DataContext.Provider;
const DataContextConsumer = DataContext.Consumer;

const DataContextProvider = (props: Props) => {

    const userContext = useContext(UserContext);
    const [decks, setDecks] = useState<Decks | null>(null);
    const snackbarContext = useSnackbar();

    useEffect(() => {
        logger.info('Fetching Configuration...');
        const connectorInstance = userContext.getInstance();
        connectorInstance.getDecks().then((remoteDecks) => {
            setDecks(remoteDecks);
        }).catch(() => {
            snackbarContext.enqueueSnackbar(
                'Konfigurationsmöglichkeiten konnten nicht geladen werden.',
                { variant: 'error' },
            );
        });
    },        []);

    if (!decks) {
        logger.warn('There are no decks. Returning Null.');
        return null;
    }

    return (
        <DCProvider value={{ decks }}>
            {props.children}
        </DCProvider>
    );

};

export {
    DataContext,
    DataContextProvider,
    DataContextConsumer,
};
