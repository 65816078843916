import React, { useContext } from 'react';
import { Button, GridList, GridListTile, GridListTileBar } from '@material-ui/core';

import s from './Type.css';
import ConfigurationContext from '../../Context/ConfigurationContext';
import StepContext from '../../Context/StepContext';
import useMediaQuery, { Breakpoint, BreakpointType } from '../../Hooks/useMediaQuery';
import { DataContext } from '../../Context/DataContext';
import { ExtraItem } from '../../Types/ConfigurationTypes';

const Type = (): JSX.Element => {

    const dataContext = useContext(DataContext);
    const userConfiguration = useContext(ConfigurationContext);
    const stepContext = useContext(StepContext);
    const [, setCompleted] = stepContext.isCompleted[stepContext.currentStep];

    const isMobile = useMediaQuery(BreakpointType.DOWN, Breakpoint.SM);

    return (
        <div className={s.Type}>
            <GridList cellHeight={300} cols={isMobile ? 1 : 2}>
                {dataContext.decks.map((deckType) => {

                    const [selectedDeckType, setSelectedDeckType] = userConfiguration.deckType;
                    // extraItems needs to be initialized with "isRequired" extra items, which are specific per type.
                    const [extraItems, setExtraItems] = userConfiguration.extraItems;
                    const isSelected = selectedDeckType ? selectedDeckType === deckType.id : false;

                    return (
                        <GridListTile
                            key={deckType.id}
                        >
                            <img src={deckType.url}/>
                            <GridListTileBar
                                title={deckType.name}
                                actionIcon={
                                    <Button
                                        style={{
                                            margin: '0 1em',
                                        }}
                                        variant={'contained'}
                                        color={isSelected ? 'secondary' : 'primary'}
                                        onClick={() => {
                                            setSelectedDeckType(deckType.id);
                                            setCompleted(true);

                                            // We have to clean up extraItems. We have to remove items,
                                            // which is not inserted from user.
                                            // isRequired get inserted automagically.

                                            const cleanedExtraItems = extraItems.filter(eI => !eI.isRequired);
                                            const newRequiredExtraItems: ExtraItem[] = deckType.extraItems
                                                .filter(eI => eI.isRequired)
                                                .map((eI) => {
                                                    return {
                                                        isRequired: eI.isRequired,
                                                        itemCount: 1,
                                                        selectedId: eI.id,
                                                    };
                                                });
                                            cleanedExtraItems.push(...newRequiredExtraItems);

                                            setExtraItems(cleanedExtraItems);

                                        }}
                                    >
                                        Auswählen
                                    </Button>
                                }
                            />
                        </GridListTile>
                    );

                })}
            </GridList>
        </div>
    );
};

export default Type;
