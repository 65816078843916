exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Site-AccountSite-AccountSite__AccountSite--2Pfyf {\n    max-width: 1000px;\n    width: 100%;\n    margin: 0 auto;\n}\n", ""]);

// exports
exports.locals = {
	"AccountSite": "src-Site-AccountSite-AccountSite__AccountSite--2Pfyf"
};