import React, { useContext, useEffect, useState } from 'react';
import ButtonBar from '../../Components/ButtonBar/ButtonBar';
import { Button, TextField } from '@material-ui/core';
import AccountContext, { AccountContextType } from '../../Context/AccountContext';
import { UserContext } from '../../Context/UserContext';

import s from './AccountSite.css';
import { useSnackbar } from '@creatdevsolutions/notistack';
import { UserDetailsForm } from '../../Components/UserForms/UserForms';

type PasswordChangeTextFieldProps = {
    name: string,
    displayName: string,
    value: string,
    onChange: (value: string) => void,
    hasError?: boolean,
};

const PasswordChangeTextField = (props: PasswordChangeTextFieldProps) => {
    return (
        <TextField
            fullWidth
            margin={'dense'}
            name={props.name}
            variant={'outlined'}
            type={'password'}
            label={props.displayName}
            value={props.value}
            error={props.hasError}
            helperText={props.hasError && 'Passwörter müssen überein stimmen.'}
            onChange={(e) => {
                const string = e.target.value as string;
                props.onChange(string);
            }}
        />
    );
};

const AccountSite = () => {

    const accountContext: AccountContextType = {
        phoneNumber: useState(''),
        eMail: useState(''),
        password: useState(''),
    };

    const [newPassword, setPassword] = useState('');
    const [newPasswordConfirmation, setPasswordRepetition] = useState('');

    const userContext = useContext(UserContext);
    const snackbarContext = useSnackbar();

    const onAccountSave = () => {
        const cInstance = userContext.getInstance();

        cInstance.updateUserOnServer({
            phoneNumber: accountContext.phoneNumber[0],
            eMail: accountContext.eMail[0],
        }).then(() => {
            snackbarContext.enqueueSnackbar('Daten wurden gespeichert.', { variant: 'success' });
        }).catch(() => {
            snackbarContext.enqueueSnackbar('Daten konnten nicht gespeichert werden.', { variant: 'error' });
        });
    };

    const onPasswordChange = () => {
        const cInstance = userContext.getInstance();

        cInstance.updateUserPasswordOnServer(newPassword).then(() => {
            setPassword('');
            setPasswordRepetition('');
            snackbarContext.enqueueSnackbar('Passwort wurde geändert.', { variant: 'success' });
        }).catch(() => {
            snackbarContext.enqueueSnackbar('Passwort konnte nicht geändert werden,.', { variant: 'error' });
        });
    };

    useEffect(() => {
        const cInstance = userContext.getInstance();

        cInstance.getCurrentUser().then((currentUser) => {
            accountContext.phoneNumber[1](currentUser.phoneNumber);
            accountContext.eMail[1](currentUser.eMail);
        });

    },        []);

    return (
        <div className={s.AccountSite}>
            <ButtonBar title={'Account'}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={onAccountSave}
                >
                    Speichern
                </Button>
            </ButtonBar>
            <AccountContext.Provider
                value={accountContext}
            >
                <UserDetailsForm
                    hidePassword
                />
            </AccountContext.Provider>
            <div style={{ marginBottom: '1em' }}/>
            <ButtonBar title={'Passwort ändern'} >
                <Button
                    variant={'contained'}
                    color={'primary'}
                    disabled={!newPassword || newPassword !== newPasswordConfirmation}
                    onClick={onPasswordChange}
                >
                    Speichern
                </Button>
            </ButtonBar>

            <div>
                <PasswordChangeTextField
                    name={'newPassword'}
                    displayName={'Passwort'}
                    value={newPassword}
                    onChange={setPassword}
                />
                <PasswordChangeTextField
                    name={'passwordConfirm'}
                    displayName={'Passwortwiederholung'}
                    value={newPasswordConfirmation}
                    hasError={newPassword !== newPasswordConfirmation}
                    onChange={setPasswordRepetition}
                />
            </div>
        </div>

    );
};

export default AccountSite;
