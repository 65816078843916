exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Site-SettingsSite-SettingsSite__SettingsSite--4K1oZ {\n    max-width: 1000px;\n    width: 100%;\n    margin: 0 auto;\n}\n\n.src-Site-SettingsSite-SettingsSite__SettingsEntries--3RElx {\n    display: grid;\n    grid-column-gap: 2em;\n    grid-template-columns: repeat(3, 1fr);\n    margin-bottom: 2em;\n}\n\n", ""]);

// exports
exports.locals = {
	"SettingsSite": "src-Site-SettingsSite-SettingsSite__SettingsSite--4K1oZ",
	"SettingsEntries": "src-Site-SettingsSite-SettingsSite__SettingsEntries--3RElx"
};