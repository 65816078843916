import React from 'react';
import { StateEntry } from './Types';

export type FinalizeContextType = {
    sendOffer: StateEntry<boolean>
    createUser: StateEntry<boolean>,
    useBillingAddressAsShippingAddress: StateEntry<boolean>,
};

const generateStateEntryWithEmptyChangeFunction = <T>(value: T): StateEntry<T> => {
    return [
        value,
        () => {

        },
    ];
};

const generateFinalizeDefaultContext: () => FinalizeContextType = () => {
    return {
        sendOffer: generateStateEntryWithEmptyChangeFunction<boolean>(true),
        createUser: generateStateEntryWithEmptyChangeFunction<boolean>(true),
        useBillingAddressAsShippingAddress: generateStateEntryWithEmptyChangeFunction<boolean>(true),
    };
};

const defaultFinalizeContext = generateFinalizeDefaultContext();

const FinalizeContext = React.createContext<FinalizeContextType>(defaultFinalizeContext);

export default FinalizeContext;

export {
    generateFinalizeDefaultContext,
};
