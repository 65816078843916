import appStyles from './App.css';
import React, { useContext } from 'react';
import Content from './Components/Content';
import { createMuiTheme } from '@material-ui/core/styles/index';
import Sidebar from './Components/Sidebar/Sidebar';
import Header from './Components/Header';

import * as classNames from 'classnames';
import { ThemeProvider } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core';

import * as Logger from 'logplease';
import { AppearanceContext, AppearanceProvider } from './Context/AppearanceContext';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserContext, UserProvider } from './Context/UserContext';
import useMediaQuery, { Breakpoint, BreakpointType } from './Hooks/useMediaQuery';
import { NotificationContainer } from './Components/Notification/NotificationContainer';

const logger = Logger.create('App.tsx');

type InnerAppProps = {
    history: any,
};

const InnerApp = (props: InnerAppProps) => {

    const userContext = useContext(UserContext);
    const appearanceContext = useContext(AppearanceContext);
    const isMobile = useMediaQuery(BreakpointType.DOWN, Breakpoint.SM);

    const showSidebar = userContext.isAuthenticated()
        && appearanceContext.isSidebarVisible
        && !userContext.isAnonymous();

    const showMenu = userContext.isAuthenticated()
        && !userContext.isAnonymous();

    const appLayoutContainerClassNames = classNames({
        [appStyles.AppLayoutContainerSidebarOpen]: showSidebar,
        [appStyles.AppLayoutContainer]: true,

    });

    const contentClassNames = classNames({
        [appStyles.Content]: true,
        [appStyles.ContentIsMobile]: isMobile,
    });

    logger.debug('Rerender Inner App');

    return (
        <NotificationContainer>
            <div className={appLayoutContainerClassNames}>
                {
                    showSidebar ?
                        <div className={appStyles.Sidebar}>
                            <Sidebar/>
                        </div>
                        : null
                }

                <div className={contentClassNames}>
                    <Header
                        user={userContext.user}
                        showMenuButton={showMenu}
                        showLogoutButton={userContext.isAuthenticated()}
                        onClick={() => {
                            appearanceContext.toggleSidebar();
                        }}
                        onLogout={() => {
                            userContext.logoutUser().then(() => {
                                props.history.push('/');
                            });
                        }}
                    />
                    <Content/>
                </div>
            </div>
        </NotificationContainer>

    );
};

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#77a630',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#e53935',
        },
    },
    typography: {
        useNextVariants: true,
    },
});

const App = (props: RouteComponentProps) => {

    logger.debug('Rerender App.tsx');

    return (
        <UserProvider>
            <MuiThemeProvider theme={appTheme}>
                <ThemeProvider theme={appTheme}>
                    <AppearanceProvider>
                        <InnerApp
                            history={props.history}
                        />
                    </AppearanceProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </UserProvider>
    );
};

export default withRouter(App);
