import { ClientDeckConfiguration, ConfigurationStatus, ServerDeckConfiguration } from '../Types/ConfigurationTypes';
import { Decks } from '../Types/DeckTypes';

const useServerConfiguration = (deckConfiguration: ServerDeckConfiguration, decks: Decks): ClientDeckConfiguration => {

    const deckType = decks.find(d => d.id === deckConfiguration.deckType);

    const baseReturnValues = {
        id: deckConfiguration.id,
        configurationStatus: ConfigurationStatus.SAVED,
        width: deckConfiguration.width,
        height: deckConfiguration.height,
        depth: deckConfiguration.depth,
        deckType: null,
        roofType: null,
        colorType: null,
        coverType: null,
        extraItems: [],
        freeItems: [],
    };

    if (!deckType) {
        return baseReturnValues;
    }

    return {
        deckType,
        id: deckConfiguration.id,
        configurationStatus: deckConfiguration.configurationStatus,
        width: deckConfiguration.width,
        height: deckConfiguration.height,
        depth: deckConfiguration.depth,
        roofType: deckType.roofTypes.find(r => r.id === deckConfiguration.roofType),
        colorType: deckType.colorTypes.find(c => c.id === deckConfiguration.colorType),
        coverType: deckType.coverTypes.find(c => c.id === deckConfiguration.coverType),
        extraItems: (deckConfiguration.extraItems || []).map(
            eI => deckType.extraItems.find((eItem) => {
                return eItem.id === eI.id;
            })!,
        ),
        freeItems: deckConfiguration.freeItems.map((freeItem) => {
            return {
                ...freeItem,
                fakeId: Math.random().toString(36).substr(2, 8),
            };
        }),
    };
};

export default useServerConfiguration;
