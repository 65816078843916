export type BaseUser = {
    phoneNumber: string
    eMail: string,
};

export enum AccountTypes {
    ANONYMOUS = 'ANONYMOUS',
    REGISTERED = 'REGISTERED',
    ADMIN = 'ADMIN',
}

export type User = BaseUser & {
    id: number,
    accountType: AccountTypes,
    passwordHash: string,
};

export type UpdateUserType = BaseUser & {
    password?: string,
};
