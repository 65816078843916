import React, { Fragment, useContext, useEffect, useState } from 'react';
import useRouter from '../../Hooks/useRouter';
import ButtonBar from '../../Components/ButtonBar/ButtonBar';
import { Button, Grid, List, ListItem, ListItemText, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import { UserContext } from '../../Context/UserContext';
import PriceView from '../ConfigurationSite/PriceView';
import ConfigurationContext from '../../Context/ConfigurationContext';
import s from './ConfigurationDetailSite.css';
import { AccountTypes } from '../../Types/UserTypes';
import {
    ConfigurationStatus,
    EditDeckConfiguration,
    ExtraItem,
    FreeItemWithFakeId,
    ServerDeckConfiguration,
} from '../../Types/ConfigurationTypes';
import AddressList from '../../Components/AddressList/AddressList';
import DocumentList from '../../Components/DocumentList/DocumentList';
import { useSnackbar } from '@creatdevsolutions/notistack';
import NewFreeItem from '../../Components/FreeItem/NewFreeItem';

type StepperProps = {
    configurationStatus: ConfigurationStatus,
};

const StatusStepper = (props:StepperProps) => {

    const {
        configurationStatus,
    } = props;

    const offerConfirmedCompleted = configurationStatus === ConfigurationStatus.OFFER_CONFIRMED;
    const offerRequestCompleted = configurationStatus === ConfigurationStatus.OFFER_REQUESTED
        || offerConfirmedCompleted;
    const configurationCompleted = configurationStatus === ConfigurationStatus.SAVED
        || offerRequestCompleted;

    return (
        <Stepper
            alternativeLabel
        >
            <Step completed={configurationCompleted}>
                <StepLabel>Konfiguration erstellt</StepLabel>
            </Step>
            <Step completed={offerRequestCompleted}>
                <StepLabel>Angebot angefordert</StepLabel>
            </Step>
            <Step completed={offerConfirmedCompleted}>
                <StepLabel>Angebot wurde bestätigt</StepLabel>
            </Step>
        </Stepper>
    );
};

const ConfigurationDetailSite = () => {

    const routerContext = useRouter();
    const {
        itemId: configurationId,
    } = routerContext.match.params as any;

    const userContext = useContext(UserContext);
    const snackbarContext = useSnackbar();
    const isAdmin = userContext.user!.accountType === AccountTypes.ADMIN;

    const [userConfiguration, setUserConfiguration] = useState<ServerDeckConfiguration | null>(null);
    const [isRequesting, setRequesting] = useState(false);
    const [isEdited, setEdited] = useState(false);

    const onRequestOffer = () => {
        setRequesting(true);
        const connectorInstance = userContext.getInstance();
        connectorInstance.requestOfferOnServer(configurationId).then((userConfiguration) => {
            setUserConfiguration(userConfiguration);
            snackbarContext.enqueueSnackbar(
                'Das Angebot wurde erfolgreich angefragt. ' +
                'Sie werden eine Benachrichtigung bekommen, insofern sich der Status ihres aktuellen Projekts ändert.',
                { variant: 'success' },
            );
            setRequesting(false);
        }).catch(() => {
            setRequesting(false);
            snackbarContext.enqueueSnackbar('Das Angebot konnte nicht angefragt werden.', { variant: 'error' });
        });
    };

    const onRequestAuthorize = () => {
        setRequesting(true);
        const connectorInstance = userContext.getInstance();
        connectorInstance.authorizeOfferOnServer(configurationId).then((userConfiguration) => {
            setUserConfiguration(userConfiguration);
            snackbarContext.enqueueSnackbar(
                'Das Angebot wurde erfolgreich autorisiert.',
                { variant: 'success' },
            );
            setRequesting(false);
        }).catch(() => {
            setRequesting(false);
            snackbarContext.enqueueSnackbar('Das Angebot konnte nicht autorisiert werden.', { variant: 'error' });
        });
    };

    useEffect(() => {
        const connectorInstance = userContext.getInstance();
        connectorInstance.getConfiguration(configurationId).then((configuration) => {
            setUserConfiguration(configuration);
        });

    },        []);

    if (!userConfiguration) {
        return null;
    }

    const mappedFreeItems = userConfiguration.freeItems.map((fI) => {

        debugger;

        return {
            ...fI,
            fakeId: Math.random().toString(36).substr(2, 8),
        };
    });

    const configurationContextProviderValue: EditDeckConfiguration = {
        id: configurationId,
        configurationStatus: useState<ConfigurationStatus | null>(userConfiguration.configurationStatus),
        roofType: useState<string | null>(userConfiguration.roofType),
        deckType: useState<string | null>(userConfiguration.deckType),
        colorType: useState<string | null>(userConfiguration.colorType),
        coverType: useState<string | null>(userConfiguration.coverType),
        extraItems: useState<ExtraItem[]>(userConfiguration.extraItems),
        freeItems: useState<FreeItemWithFakeId[]>(mappedFreeItems),
        height: useState<number>(userConfiguration.height),
        width: useState<number>(userConfiguration.width),
        depth: useState<number>(userConfiguration.depth),
    };

    const onSaveClick = () => {
        const connectorInstance = userContext.getInstance();
        connectorInstance.updateConfigurationOnServer(
            configurationContextProviderValue,
            userConfiguration.billingAddress,
            userConfiguration.shippingAddress,
        ).then((configuration) => {
            setUserConfiguration(configuration);
            setEdited(false);
        });
    };

    return (
        <div>
            <ConfigurationContext.Provider
                value={configurationContextProviderValue}
            >
                <ButtonBar
                    title={`Detailierte Informationen zu Konfiguration ${configurationId}`}
                >
                    {
                        isAdmin && userConfiguration.configurationStatus !== ConfigurationStatus.OFFER_CONFIRMED
                            ? <Button
                                disabled={isRequesting || isEdited}
                                variant={'contained'}
                                color={'secondary'}
                                onClick={onRequestAuthorize}
                            >
                                Autorisieren
                            </Button>
                            : null }
                    {
                        userConfiguration.configurationStatus === ConfigurationStatus.SAVED
                            ?
                            <Fragment>
                                <Button
                                    disabled={isRequesting}
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={onRequestOffer}
                                >
                                    Angebot anfragen
                                </Button>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => routerContext.history.push(`/configuration/${configurationId}/edit`)}
                                >
                                    Bearbeiten
                                </Button>
                            </Fragment>
                            : null
                    }
                    {
                        isAdmin && isEdited
                            ? <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={onSaveClick}
                            >
                                Speichern
                            </Button>
                            : null
                    }

                </ButtonBar>
                <Grid container spacing={16}>
                    <Grid
                        xs={12}
                        lg={6}
                        item
                    >
                        <Typography variant={'h6'}>Übersicht</Typography>
                        <StatusStepper
                            configurationStatus={userConfiguration.configurationStatus}
                        />
                        <Grid container>
                            <Grid item xs={12} lg={6}>
                                <Typography className={s.SubHeader} variant={'h6'}>Rechnungsadresse</Typography>
                                <AddressList
                                    address={userConfiguration.billingAddress}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Typography className={s.SubHeader} variant={'h6'}>Lieferadresse</Typography>
                                <AddressList
                                    address={userConfiguration.shippingAddress}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={s.SubHeader} variant={'h6'}>Kontakt</Typography>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemText
                                            primary={userConfiguration.user.eMail}
                                            secondary={'E-Mail'}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters>
                                        <ListItemText
                                            primary={userConfiguration.user.phoneNumber}
                                            secondary={'Telefon'}
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        xs={12}
                        lg={6}
                        item
                    >
                        <Typography variant={'h6'}>Kosten</Typography>
                        <PriceView
                            isExtended
                            isStandalone
                            areFreeItemsRemovable={
                                userConfiguration.configurationStatus !== ConfigurationStatus.OFFER_CONFIRMED
                            }
                            onFreeItemRemove={() => setEdited(true)}
                        />
                        {
                            isAdmin && userConfiguration.configurationStatus !== ConfigurationStatus.OFFER_CONFIRMED  ?
                                <Fragment>
                                    <Typography variant={'h6'}>Freie Items</Typography>
                                    <NewFreeItem
                                        onNewItem={() => setEdited(true)}
                                    />
                                </Fragment>
                                : null
                        }
                        {
                            userConfiguration.configurationStatus === ConfigurationStatus.OFFER_CONFIRMED
                                ? <Fragment>
                                    <Typography className={s.SubHeader} variant={'h6'}>Dokumente</Typography>
                                    <DocumentList
                                        configurationId={configurationId}
                                    />
                                </Fragment>
                                : null
                        }
                    </Grid>

                </Grid>
            </ConfigurationContext.Provider>
        </div>
    );

};

export default ConfigurationDetailSite;
