import React from 'react';
import ConfigurationSite from '../ConfigurationSite/ConfigurationSite';
import { RouteComponentProps, withRouter } from 'react-router';

const ModifyConfigurationSite = (props: RouteComponentProps) => {

    const configurationId = (props.match.params as any).itemId;

    return (
        <ConfigurationSite
            configurationId={configurationId}
        />
    );
};

export default withRouter(ModifyConfigurationSite);
