import React, { useState } from 'react';
import useMediaQuery, { Breakpoint, BreakpointType } from '../Hooks/useMediaQuery';

import Logger from 'logplease';

const logger = Logger.create('AppearanceContext.tsx');

type AppearanceState = {
    isSidebarVisible: boolean
    toggleSidebar: () => void,
};

const defaultValue: AppearanceState = {
    isSidebarVisible: false,
    toggleSidebar: () => {
    },
};

const AppearanceContext = React.createContext<AppearanceState>(defaultValue);
const AProvider = AppearanceContext.Provider;
const AppearanceConsumer = AppearanceContext.Consumer;

type Props = {
    children: JSX.Element,
};

const AppearanceProvider = (props: Props) => {

    const isMobile = useMediaQuery(BreakpointType.DOWN, Breakpoint.SM);
    const [isSidebarVisible, setSidebarVisible] = useState(!isMobile);

    const toggleSidebar = () => {

        setSidebarVisible(!isSidebarVisible);
    };

    logger.debug('window.innerWidth', window.innerWidth);
    logger.debug('isMobile', isMobile);
    logger.debug('isSidebarVisible', isSidebarVisible);

    const currentState: AppearanceState = {
        isSidebarVisible,
        toggleSidebar,
    };

    return (
        <AProvider value={currentState}>
            {props.children}
        </AProvider>
    );
};

export {
    AppearanceContext,
    AppearanceProvider,
    AppearanceConsumer,
} ;
