import {
    Avatar,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { UserContext } from '../../Context/UserContext';

type Props = {
    configurationId: number,
};

const DocumentList = (props: Props) => {

    const userContext = useContext(UserContext);

    const onDownloadClick = () => {
        const connectorInstance = userContext.getInstance();
        connectorInstance.getTokenForPdf(props.configurationId).then((payload) => {
            window.open(`/api/pdf/${props.configurationId}?token=${payload.pdfToken}`);
        });
    };

    return (
        <List disablePadding>
            <ListItem
                disableGutters
            >
                <Avatar>
                    <Icon>
                        file_copy
                    </Icon>
                </Avatar>
                <ListItemText
                    primary={'Angebot'}
                />
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={onDownloadClick}
                    >
                        <Icon>
                            save_alt
                        </Icon>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>

        </List>
    );
};

export default DocumentList;
