import * as React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Address } from '../../Types/ConfigurationTypes';

type UserAddressProps = {
    address: Address,
};

const AddressList = (props: UserAddressProps) => {

    const {
        address,
    } = props;

    return (
        <List disablePadding>
            <ListItem disableGutters>
                <ListItemText
                    primary={`${address.firstName} ${address.lastName}`}
                    secondary={'Name'}
                />
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primary={`${address.streetName} ${address.streetNumber}`}
                    secondary={'Adresse'}
                />
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primary={`${address.zipCode} ${address.city}`}
                    secondary={'Postleitzahl und Ort'}
                />
            </ListItem>
        </List>
    );
};

export default AddressList;
