import Logger from 'logplease';
import axios, { AxiosResponse } from 'axios';
import { UpdateUserType, User } from './Types/UserTypes';
import { PartPrices, PriceType } from './Types/PriceType';
import { Decks } from './Types/DeckTypes';
import {
    Address,
    EditDeckConfiguration,
    ExtraItem,
    FreeItemWithFakeId,
    ServerDeckConfiguration,
} from './Types/ConfigurationTypes';

const REACT_APP_API_OFFER_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const REACT_APP_API_AUTH_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const logger = Logger.create('Connector.ts');

logger.debug('REACT_APP_API_OFFER_ENDPOINT', REACT_APP_API_OFFER_ENDPOINT);
logger.debug('REACT_APP_API_AUTH_ENDPOINT', REACT_APP_API_AUTH_ENDPOINT);

/**
 * Key, which is used to save the token in the localStorage.
 */
const localStorageKey = 'traumterrassenUserToken';
const localStorageKeyVersion = 'traumterrassenUserTokenVersion';

const CURRENT_TOKEN_VERSION = 3;

/**
 * Connector is used to talk to the backend.
 * Therefore we do not need to distribute such logic in the entire application.
 */
class Connector {

    /**
     * This is the identifier, which is generated per user from the server.
     * With this token, the user can obtain it's configuration.
     * Right now, this token is not associated with any other data, it is just an unique identifier.
     * If there is an association later on to name and address, we need to keep in mind,
     * that this may be a data protection issue.
     */
    private userToken: string | null = null;

    public static async requestResetPassword(eMail: string) {
        const resetParams = {
            eMail,
        };

        await axios.post(`${REACT_APP_API_AUTH_ENDPOINT}/auth/resetPassword`, resetParams);
    }

    public static async resetPassword(userId: string, interactionToken: string, newPassword: string) {
        const resetParams = {
            userId,
            interactionToken,
            newPassword,
        };

        await axios.post(`${REACT_APP_API_AUTH_ENDPOINT}/auth/setPassword`, resetParams);
    }

    private static async createInstanceFromEndpoint(isAnonymous: boolean, eMail?: string, passwordInput?: string) {

        const authParameter = {
            isAnonymous,
            eMail,
            passwordInput,
        };

        const authReply = await axios.post(`${REACT_APP_API_AUTH_ENDPOINT}/auth`, authParameter);
        const userToken = authReply.data.userToken;

        localStorage.setItem(localStorageKey, userToken);
        localStorage.setItem(localStorageKeyVersion, CURRENT_TOKEN_VERSION.toString());

        const newConnectorInstance = new Connector();
        newConnectorInstance.userToken = userToken;

        return newConnectorInstance;

    }

    public static async createInstanceFromAnonymous(): Promise<Connector> {
        return this.createInstanceFromEndpoint(true);
    }

    /**
     * You can create a Connector from user credentials.
     */
    public static async createInstanceFromCredentials(eMail: string, passwordInput: string): Promise<Connector> {
        return this.createInstanceFromEndpoint(false, eMail, passwordInput);
    }

    /**
     * You can create a Connector from LocalStorage. You need an token in your local storage.
     * Otherwise it will fail with an exception.
     */
    public static async createInstanceFromLocalStorage(): Promise<Connector> {
        const tokenVersion = localStorage.getItem(localStorageKeyVersion);

        if (!tokenVersion || Number(tokenVersion) < CURRENT_TOKEN_VERSION) {
            localStorage.removeItem(localStorageKey);
        }

        const currentUserToken = localStorage.getItem(localStorageKey);

        if (!currentUserToken) {
            throw Error('Could not construct an instance from LocalStorage due to a missing user token.');
        }

        const newConnectorInstance = new Connector();
        newConnectorInstance.userToken = currentUserToken;

        return newConnectorInstance;
    }

    public async removeInstance() {
        localStorage.removeItem(localStorageKey);
        localStorage.removeItem(localStorageKeyVersion);
        this.userToken = null;
    }

    /**
     * Generated a axiosConfig object, which contains the needed arguments to be authorized to do a request.
     * Right now, we only need a userToken to be able to use the API.
     * The userToken is the identifier for the users configuration.
     */
    private getAxiosConfig() {
        return {
            headers: {
                'X-Token': this.userToken,
            },
        };
    }

    /**
     * We use a helper at this point, which is aware of errors during the request.
     */
    private async processRequest<T>(axiosRequest: Promise<AxiosResponse<T>>) {

        return axiosRequest.then((axiosReply) => {
            return axiosReply.data;
        }).catch((error) => {
            logger.error(error);
            throw error;
        });
    }

    /**
     * Shorthand function to obtain the general configuration. This includes all possible configurations.
     */
    async getDecks(): Promise<Decks> {
        const axiosConfig = this.getAxiosConfig();
        return await this.processRequest<Decks>(
            axios.get(`${REACT_APP_API_OFFER_ENDPOINT}/decks`,
                      axiosConfig,
            ),
        );
    }

    async getConfigurations(): Promise<ServerDeckConfiguration[]> {
        const axiosConfig = this.getAxiosConfig();
        return await this.processRequest<ServerDeckConfiguration[]>(
            axios.get(`${REACT_APP_API_OFFER_ENDPOINT}/offer`,
                      axiosConfig,
            ),

        );
    }

    async getConfiguration(configurationId: number): Promise<ServerDeckConfiguration> {
        const axiosConfig = this.getAxiosConfig();
        return await this.processRequest<ServerDeckConfiguration>(
            axios.get(`${REACT_APP_API_OFFER_ENDPOINT}/offer/${configurationId}`,
                      axiosConfig,
            ),

        );

    }

    async getCurrentUser(): Promise<User> {
        const axiosConfig = this.getAxiosConfig();
        const currentUser = await axios.get<User>(
            `${REACT_APP_API_AUTH_ENDPOINT}/user`,
            axiosConfig,
        ).then(r => r.data);

        return currentUser;
    }

    async getCurrentPrice(
        width: number,
        height: number,
        depth: number,
        deckType: string | null,
        roofType: string | null,
        colorType: string | null,
        coverType: string | null,
        extraItems: ExtraItem[],
        freeItems: FreeItemWithFakeId[],
    ) {

        const requestBody = {
            width,
            height,
            depth,
            deckType,
            roofType,
            colorType,
            coverType,
            extraItems,
            freeItems,
        };

        const axiosConfig = this.getAxiosConfig();
        return this.processRequest<PriceType>(axios.post<PriceType>(
            `${REACT_APP_API_OFFER_ENDPOINT}/price`,
            requestBody,
            axiosConfig,
        ));
    }

    async updateUserOnServer(user: UpdateUserType) {
        const axiosConfig = this.getAxiosConfig();
        const requestBody = {
            ...user,
        };

        await axios.post(`${REACT_APP_API_AUTH_ENDPOINT}/user`, requestBody, axiosConfig);
    }

    async updateUserPasswordOnServer(newPassword: string) {
        const axiosConfig = this.getAxiosConfig();
        const requestBody = {
            password: newPassword,
        };

        await axios.post(`${REACT_APP_API_AUTH_ENDPOINT}/user`, requestBody, axiosConfig);
    }

    async updateConfigurationOnServer(
        deckConfiguration: EditDeckConfiguration,
        billingAddress: Address,
        shippingAddress: Address,
    ) {
        const axiosConfig = this.getAxiosConfig();
        const requestBody = {
            billingAddress,
            shippingAddress,
            configurationId: deckConfiguration.id,
            width: deckConfiguration.width[0],
            height: deckConfiguration.height[0],
            depth: deckConfiguration.depth[0],
            deckType: deckConfiguration.deckType[0],
            roofType: deckConfiguration.roofType[0],
            colorType: deckConfiguration.colorType[0],
            coverType: deckConfiguration.coverType[0],
            extraItems: deckConfiguration.extraItems[0],
            freeItems: deckConfiguration.freeItems[0],
        };

        return this.processRequest<ServerDeckConfiguration>(
            axios.post(`${REACT_APP_API_OFFER_ENDPOINT}/offer`, requestBody, axiosConfig),
        );
    }

    async createConfigurationOnServer(
        deckConfiguration: EditDeckConfiguration,
        billingAddress: Address,
        shippingAddress: Address,
    ) {
        const axiosConfig = this.getAxiosConfig();

        const requestBody = {
            billingAddress,
            shippingAddress,
            width: deckConfiguration.width[0],
            height: deckConfiguration.height[0],
            depth: deckConfiguration.depth[0],
            deckType: deckConfiguration.deckType[0],
            roofType: deckConfiguration.roofType[0],
            colorType: deckConfiguration.colorType[0],
            coverType: deckConfiguration.coverType[0],
            extraItems: deckConfiguration.extraItems[0],
        };

        return this.processRequest<ServerDeckConfiguration>(
            axios.put(`${REACT_APP_API_OFFER_ENDPOINT}/offer`, requestBody, axiosConfig),
        );

    }

    async requestOfferOnServer(
        configurationId: number,
    ) {
        const axiosConfig = this.getAxiosConfig();
        const requestBody = {
            configurationId,
        };
        return this.processRequest<ServerDeckConfiguration>(
            axios.post(`${ REACT_APP_API_OFFER_ENDPOINT }/offer/requestOffer`, requestBody, axiosConfig),
        );
    }

    async authorizeOfferOnServer(
        configurationId: number,
    ) {
        const axiosConfig = this.getAxiosConfig();
        const requestBody = {
            configurationId,
        };
        return this.processRequest<ServerDeckConfiguration>(
            axios.post(`${ REACT_APP_API_OFFER_ENDPOINT }/offer/authorizeOffer`, requestBody, axiosConfig),
        );
    }

    async getTokenForPdf(configurationId: number) {
        const axiosConfig = this.getAxiosConfig();
        return this.processRequest<any>(
            axios.get(`${ REACT_APP_API_OFFER_ENDPOINT }/offer/${configurationId}/pdf`, axiosConfig),
        );
    }

    async getPartPrices() {
        const axiosConfig = this.getAxiosConfig();
        return this.processRequest<PartPrices>(
            axios.get(`${ REACT_APP_API_OFFER_ENDPOINT }/partprice`, axiosConfig),
        );
    }

    async savePartPrices(partPrices: PartPrices) {
        const axiosConfig = this.getAxiosConfig();
        return this.processRequest<any>(
            axios.put(`${ REACT_APP_API_OFFER_ENDPOINT }/partprice`, partPrices, axiosConfig),
        );
    }
}

export default Connector;
