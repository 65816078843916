exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Components-Content__Content--3rC43 {\n    overflow-y: auto;\n\n    -webkit-overflow-scrolling: touch;\n\n    padding: 2em;\n    flex-grow: 1;\n}\n", ""]);

// exports
exports.locals = {
	"Content": "src-Components-Content__Content--3rC43"
};