import { Avatar, Dialog, Icon, IconButton } from '@material-ui/core';
import Logger from 'logplease';
import s from './ZoomableImage.css';
import React, { useState, Fragment } from 'react';

const logger = Logger.create('ZoomableImage.tsx');

type Props = {
    src: string;
};

const ZoomableImage = (props: Props) => {

    const [isDialogOpen, setDialogOpen] = useState <boolean>(false);

    const onAvatarClick = () => {
        logger.debug('onAvatarClick called.');
        setDialogOpen(true);
    };

    return (
        <Fragment>
            <Avatar className={s.Avatar} src={props.src} onClick={onAvatarClick}/>
            <Dialog
                open={isDialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <div className={s.IconWrapper}>
                    <IconButton onClick={() => setDialogOpen(false)}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
                <img className={s.Image} alt="" src={props.src}/>
            </Dialog>
        </Fragment>
    );
};

export default ZoomableImage;
