exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Site-ConfigurationSite-Type__Buttons--qwnGQ {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.src-Site-ConfigurationSite-Type__Type--17rPx {\n    width: 100%;\n\n    display: flex;\n    flex-direction: column;\n    align-content: center;\n}\n\n.src-Site-ConfigurationSite-Type__Type--17rPx > * {\n    margin: 0 auto;\n}\n", ""]);

// exports
exports.locals = {
	"Buttons": "src-Site-ConfigurationSite-Type__Buttons--qwnGQ",
	"Type": "src-Site-ConfigurationSite-Type__Type--17rPx"
};