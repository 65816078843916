import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Radio }
    from '@material-ui/core';
import ConfigurationList from './ConfigurationList';
import ZoomableImage from '../../Components/ZoomableImage/ZoomableImage';

const Color = () => {

    return (
        <ConfigurationList>
            {(userConfiguration, _, deckType, markCompleted) => {

                const [selectedColorTypeId, setColorTypeId] = userConfiguration.colorType;

                return (
                    <List
                        disablePadding
                    >
                        {deckType.colorTypes.map((colorType) => {

                            const isSelected = selectedColorTypeId ? selectedColorTypeId === colorType.id : false;

                            return (
                                <ListItem
                                    disableGutters
                                    key={colorType.id}
                                >
                                    <ListItemAvatar>
                                        <ZoomableImage
                                            src={colorType.url}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={colorType.name}
                                        secondary={colorType.ralName}
                                    />
                                    <ListItemSecondaryAction>
                                        <Radio
                                            onChange={() => {

                                                setColorTypeId(colorType.id);
                                                markCompleted();

                                            }}
                                            checked={isSelected}
                                            color={'primary'}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                );
            }}

        </ConfigurationList>
    );
};

export default Color;
