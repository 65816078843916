import React, { Fragment, useContext } from 'react';
import { Divider, MenuItem, MenuList } from '@material-ui/core';
import s from './Sidebar.css';
import logoImage from '../../../images/traumterrassenlogo.png';
import useRouter from '../../Hooks/useRouter';

import packageJson from '../../../package.json';
import { UserContext } from '../../Context/UserContext';
import { AppearanceContext } from '../../Context/AppearanceContext';
import useMediaQuery, { Breakpoint, BreakpointType } from '../../Hooks/useMediaQuery';

const Sidebar = () => {

    const routerContext = useRouter();
    const userContext = useContext(UserContext);
    const appearanceContext = useContext(AppearanceContext);
    const isMobile = useMediaQuery(BreakpointType.DOWN, Breakpoint.SM);

    const onItemClick = (routePath: string) => {
        if (isMobile) {
            appearanceContext.toggleSidebar();
        }
        routerContext.history.push(routePath);
    };

    const isAdmin = userContext.isAdmin();

    return (
        <div>
            <div className={s.Logo}
            >
                <img src={logoImage}/>
            </div>

            <Divider/>

            <MenuList>
                <MenuItem
                    button
                    onClick={() => onItemClick('/configuration')}
                >
                    Konfigurationen
                </MenuItem>
                <MenuItem
                    button
                    onClick={() => onItemClick('/account')}
                >
                    Account
                </MenuItem>
                <MenuItem
                    button
                    onClick={() => onItemClick('/legal')}
                >
                    Impressum und Datenschutz
                </MenuItem>
                {
                    isAdmin ?
                        <Fragment>
                            <MenuItem
                                button
                                onClick={() => onItemClick('/settings')}
                            >
                                Einstellungen
                            </MenuItem>
                            <Divider/>
                            <MenuItem>
                                Version {packageJson.version}
                            </MenuItem>
                        </Fragment>
                        : null

                }

            </MenuList>
        </div>
    );
};

export default Sidebar;
