import React, { useContext, useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core/es';
import ConfigurationContext from '../../Context/ConfigurationContext';
import StepContext from '../../Context/StepContext';

import * as Logger from 'logplease';
import { DataContext } from '../../Context/DataContext';
import Drawing from '../../Components/Drawing/Drawing';

// @ts-ignore
const logger = Logger.create('Measurement.tsx');

type MesaurementTextFieldProps = {
    name: string,
    displayName: string,
    onChange: (newValue: number) => void;
    value: number,
    hasError: boolean,
};

const MesaurementTextField = (props: MesaurementTextFieldProps) => {
    const [textValue, setTextValue] = useState<string>(props.value.toFixed(2));

    return (
        <Grid item xs={12} lg={4}>
            <TextField
                fullWidth
                margin={'normal'}
                type={'number'}
                error={props.hasError}
                helperText={props.hasError && 'Die Eingabe liegt außerhalb der statischen Möglichkeiten.'}
                label={props.displayName}
                name={props.name}
                onChange={(e: any) => {
                    const numberString: string = e.target.value;
                    setTextValue(numberString);
                    props.onChange(Number(numberString));
                }}
                inputProps={{
                    style: {
                        textAlign: 'right',
                    },
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                }}
                value={textValue}
            />
        </Grid>
    );
};

const Measurement = () => {

    const configurationContext = useContext(ConfigurationContext);
    const dataContext = useContext(DataContext);
    const stepContext = useContext(StepContext);

    const [width, setWidth] = configurationContext.width;
    const [height, setHeight] = configurationContext.height;
    const [depth, setDepth] = configurationContext.depth;

    const selectedDeckType = dataContext.decks.find(d => d.id === configurationContext.deckType[0])!;

    if (!selectedDeckType) {
        return null;
    }

    const isWidthCorrect = selectedDeckType.measurementIntervals.minWidth <= width
        && width <= selectedDeckType.measurementIntervals.maxWidth;

    const isHeightCorrect = selectedDeckType.measurementIntervals.minHeight <= height
        && height <= selectedDeckType.measurementIntervals.maxHeight;

    const isDepthCorrect = selectedDeckType.measurementIntervals.minDepth <= depth
        && depth <= selectedDeckType.measurementIntervals.maxDepth;

    useEffect(() => {
        const [, setCompleted] = stepContext.isCompleted[stepContext.currentStep];
        const isSiteCorrect = isWidthCorrect && isHeightCorrect && isDepthCorrect;
        setCompleted(isSiteCorrect);

    },        [isWidthCorrect, isHeightCorrect, isDepthCorrect]);

    return (
        <Grid container spacing={16}>
            <Grid item xs={12}>
                <Drawing
                    width={width}
                    height={height}
                    depth={depth}
                    isStandalone={selectedDeckType.isStandalone}
                />
            </Grid>
            <MesaurementTextField
                name={'width'}
                displayName={'Breite'}
                onChange={setWidth}
                value={width}
                hasError={!isWidthCorrect}
            />
            <MesaurementTextField
                name={'depth'}
                displayName={'Tiefe'}
                onChange={setDepth}
                value={depth}
                hasError={!isDepthCorrect}
            />
            <MesaurementTextField
                name={'height'}
                displayName={'Höhe'}
                onChange={setHeight}
                value={height}
                hasError={!isHeightCorrect}
            />
        </Grid>
    );
};

export default Measurement;
