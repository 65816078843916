import React, { useContext, useState } from 'react';

import s from './WelcomeSite.css';
import { Button, Divider, TextField, Typography } from '@material-ui/core';

import logoImage from '../../../images/traumterrassenlogo.png';
import { UserContext } from '../../Context/UserContext';

import { useSnackbar } from '@creatdevsolutions/notistack';
import useRouter from '../../Hooks/useRouter';
import Connector from '../../Connector';

type LoginProps = {
    name: string,
    displayName: string,
    isPassword: boolean,
    value: string,
    onChange: (e: any) => void,
};

const LoginTextField = (props: LoginProps) => {

    const {
        name,
        displayName,
        isPassword,
        onChange,
        value,
    } = props;

    return (
        <TextField
            fullWidth
            margin={'dense'}
            name={name}
            value={value}
            label={displayName}
            type={isPassword ? 'password' : 'text'}
            onChange={onChange}
        />
    );
};

type ExistingUserProps = {
    onLoginClick: (eMail: string, passwordInput: string) => void;
    onPasswordResetClick: (eMail: string) => Promise<void>;
};

type NewUserProps = {
    onLoginClick: () => void;
};

const ExistingUser = (props: ExistingUserProps) => {

    const [eMail, setEMail] = useState('');
    const [password, setPassword] = useState('');

    const [shouldPasswordBeResetted, setPasswordBeResetted] = useState(false);

    const onPasswordForgotClick = () => {
        if (!shouldPasswordBeResetted) {
            setPasswordBeResetted(true);
        }
    };

    const onPrimaryClick = async () => {
        if (shouldPasswordBeResetted) {
            await props.onPasswordResetClick(eMail);
            setPasswordBeResetted(false);
        } else {
            props.onLoginClick(eMail, password);
        }
    };

    return (
        <div className={s.CenterText}>
            <Typography>
                Sie haben bereits einen Account? Melden Sie sich einfach an und sehen Sie ihren aktuellen Auftrag oder
                arbeiten Sie an gespeicherten Konfigurationen weiter.
            </Typography>
            <div className={s.LoginFieldsContainer}>
                <LoginTextField
                    name={'email'}
                    displayName={'E-Mail-Adresse'}
                    isPassword={false}
                    value={eMail}
                    onChange={(e) => {
                        setEMail(e.target.value);
                    }}
                />
                {
                    !shouldPasswordBeResetted
                        ? <LoginTextField
                            name={'password'}
                            displayName={'Passwort'}
                            isPassword={true}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                        : null
                }

            </div>
            <div className={s.ButtonBar}>
                <Button
                    variant={'contained'}
                    onClick={onPasswordForgotClick}
                    disabled={shouldPasswordBeResetted}
                >
                    Passwort vergessen?
                </Button>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={onPrimaryClick}
                >
                    {shouldPasswordBeResetted ? 'E-Mail anfordern' : 'Einloggen'}
                </Button>
            </div>

        </div>
    );
};

const NewUser = (props: NewUserProps) => {
    return (
        <div className={s.CenterText}>
            <Typography>
                Sie haben noch kein Account? Gehen Sie direkt zur Konfiguration Ihrer neuen Terrassenüberdachung.
            </Typography>
            <div className={s.ButtonBar}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={props.onLoginClick}
                >
                    Zum Konfigurator
                </Button>
            </div>
        </div>
    );
};

const  WelcomeSite = () => {

    const userContext = useContext(UserContext);
    const snackbarContext = useSnackbar();
    const routerContext = useRouter();

    return (
        <div className={s.CenterCard}>
            <div className={s.FlexCenter}>
                <img
                    className={s.Logo}
                    src={logoImage}
                />
            </div>
            <Divider className={s.Divider}/>
            <NewUser
                onLoginClick={() => {
                    userContext.loginAnonymous().catch(() => {
                        snackbarContext.enqueueSnackbar('Sie konnten nicht eingeloggt werden.', { variant: 'error' });
                    });
                }}
            />
            <Divider className={s.Divider}/>
            <ExistingUser
                onLoginClick={(eMail, passwordInput) => {
                    userContext.loginWithCredentials(eMail, passwordInput).catch(() => {
                        snackbarContext.enqueueSnackbar('Sie konnten nicht eingeloggt werden.', { variant: 'error' });
                    });
                }}
                onPasswordResetClick={(eMail) => {
                    return Connector.requestResetPassword(eMail)
                        .then(() => {
                            snackbarContext.enqueueSnackbar(
                                // tslint:disable-next-line:max-line-length
                                'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie haben eine E-Mail erhalten mit einem Link, um ein neues Passwort festzulegen.',
                                { variant: 'success' },
                            );
                        })
                        .catch(() => {
                            snackbarContext.enqueueSnackbar(
                                'Ihr Passwort konnte nicht zurückgesetzt werden.',
                                { variant: 'error' },
                            );
                        });
                }}
            />
            <div className={s.LegalLink}>
                <a onClick={() => routerContext.history.push('/legal#imprint')}>Impressum</a>
                <a onClick={() => routerContext.history.push('/legal#privacy')}>Datenschutz</a>
            </div>
        </div>
    );
};

export default WelcomeSite;
