import React from 'react';
import { List, ListItem, ListItemSecondaryAction, ListItemText, Radio } from '@material-ui/core';
import ConfigurationList from './ConfigurationList';

const Roofing = () => {

    return (
        <ConfigurationList>
            {(userConfiguration, _, deckType, markCompleted) => {
                return (
                    <List
                        disablePadding
                    >
                        {deckType.roofTypes.map((roofType) => {

                            const [selectedRoofType, setSelectedRoofType] = userConfiguration.roofType;
                            const isSelected = userConfiguration.roofType ? selectedRoofType === roofType.id : false;

                            return (
                                <ListItem
                                    disableGutters
                                    key={roofType.id}
                                >
                                    <ListItemText
                                        primary={roofType.name}
                                        secondary={roofType.description}
                                    />
                                    <ListItemSecondaryAction>
                                        <Radio
                                            onChange={() => {

                                                setSelectedRoofType(roofType.id);
                                                markCompleted();

                                            }}
                                            checked={isSelected}
                                            color={'primary'}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                );
            }}

        </ConfigurationList>
    );
};

export default Roofing;
