exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Components-ZoomableImage-ZoomableImage__Avatar--17HRZ {\n    cursor: pointer;\n}\n\n.src-Components-ZoomableImage-ZoomableImage__IconWrapper--1qexV {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.src-Components-ZoomableImage-ZoomableImage__Image--1fVsb {\n    width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"Avatar": "src-Components-ZoomableImage-ZoomableImage__Avatar--17HRZ",
	"IconWrapper": "src-Components-ZoomableImage-ZoomableImage__IconWrapper--1qexV",
	"Image": "src-Components-ZoomableImage-ZoomableImage__Image--1fVsb"
};