exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Site-LegalSite-LegalSite__LegalSite--33ggt {\n    margin-top: -2em;\n    font-size: 14px;\n}\n\n.src-Site-LegalSite-LegalSite__LegalSite--33ggt h1,h2,h3,h4,h5,h6,strong {\n    font-weight: 500;\n}\n\n.src-Site-LegalSite-LegalSite__LegalSite--33ggt h1 {\n    margin: 0;\n    padding: 1em 0 0.5em 0;\n}\n", ""]);

// exports
exports.locals = {
	"LegalSite": "src-Site-LegalSite-LegalSite__LegalSite--33ggt"
};