import { Grid, TextField } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import AccountContext from '../../Context/AccountContext';
import FinalizeContext from '../../Context/FinalizeContext';
import { BillingAddressContext, ShippingAddressContext } from '../../Context/AddressContext';

type UserTextFieldProps = {
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    value: string,
    name: string,
    label: string,
    onChange: (newVal: string) => void,
    isPassword?: boolean,
    disabled?: boolean,
};

const UserTextField = (props: UserTextFieldProps) => {

    return (
        <Grid
            item
            xs={props.xs}
            md={props.md}
            lg={props.lg}
        >
            <TextField
                fullWidth
                disabled={props.disabled}
                variant={'outlined'}
                label={props.label}
                type={props.isPassword ? 'password' : 'text'}
                value={props.value}
                name={props.name}
                onChange={(e: any) => {
                    props.onChange(e.target.value as string);
                }}

            />
        </Grid>
    );
};

type UserProps = {
    hidePassword? : boolean;
    onChange?: (phoneNumber: string, eMail: string) => void;
};

const UserDetailsForm = (props: UserProps) => {

    const accountContext = useContext(AccountContext);
    const finalizeContext = useContext(FinalizeContext);

    const shouldUserBeCreated = finalizeContext.createUser[0];

    const [phoneNumber, setPhoneNumber] = accountContext.phoneNumber;
    const [eMail, setEMail] = accountContext.eMail;
    const [password, setPassword] = accountContext.password;

    return (
        <Grid container spacing={16}>

            <UserTextField
                xs={12}
                label={'Telefon'}
                name={'phoneNumber'}
                value={phoneNumber}
                onChange={(s: string) => {
                    setPhoneNumber(s);
                    props.onChange && props.onChange(s, eMail);
                }}
            />
            <UserTextField
                xs={12}
                label={'E-Mail'}
                name={'email'}
                value={eMail}
                onChange={(s: string) => {
                    setEMail(s);
                    props.onChange && props.onChange(phoneNumber, s);
                }}
            />
            {
                shouldUserBeCreated && !props.hidePassword ?
                    <UserTextField
                        xs={12}
                        label={'Passwort'}
                        name={'password'}
                        isPassword
                        value={password}
                        onChange={(s: string) => {
                            setPassword(s);
                        }}
                    />
                    : null
            }

        </Grid>

    );
};

export enum AddressFormContexts {
    BillingAddress = 'BILLING_ADDRESS',
    ShippingAddress = 'SHIPPING_ADDRESS',
}

type AddressFormProps = {
    contextToUse: AddressFormContexts,
    onCompletedChange?: (isCompleted: boolean) => void,
    disabled?: boolean,
};

const AddressForm = (props: AddressFormProps) => {

    let addressContext;
    let addressPrefix;

    if (props.contextToUse === AddressFormContexts.BillingAddress) {
        addressContext = useContext(BillingAddressContext);
        addressPrefix = 'billing';
    } else {
        addressContext = useContext(ShippingAddressContext);
        addressPrefix = 'shipping';
    }

    const [firstName, setFirstName] = addressContext.firstName;
    const [lastName, setLastName] = addressContext.lastName;
    const [streetName, setStreetName] = addressContext.streetName;
    const [streetNumber, setStreetNumber] = addressContext.streetNumber;
    const [zipCode, setZipCode] = addressContext.zipCode;
    const [city, setCity] = addressContext.city;

    useEffect(() => {

        if (!props.onCompletedChange) {
            return;
        }

        props.onCompletedChange(!!(firstName && lastName && streetName && streetNumber && zipCode && city));
    },        [
        firstName,
        lastName,
        streetName,
        streetNumber,
        zipCode,
        city,
    ]);

    return (
        <Grid container spacing={16}>
            <UserTextField
                xs={12}
                md={6}
                name={`${addressPrefix}FirstName`}
                label={'Vorname'}
                value={firstName}
                disabled={props.disabled}
                onChange={(s: string) => setFirstName(s)}
            />
            <UserTextField
                xs={12}
                md={6}
                name={`${addressPrefix}LastName`}
                label={'Nachname'}
                value={lastName}
                disabled={props.disabled}
                onChange={(s: string) => setLastName(s)}
            />
            <UserTextField
                xs={8}
                lg={10}
                label={'Straße'}
                name={`${addressPrefix}StreetName`}
                value={streetName}
                disabled={props.disabled}
                onChange={(s: string) => setStreetName(s)}
            />
            <UserTextField
                xs={4}
                lg={2}
                label={'Nr.'}
                name={`${addressPrefix}StreetNumber`}
                value={streetNumber}
                disabled={props.disabled}
                onChange={(s: string) => setStreetNumber(s)}
            />
            <UserTextField
                xs={12}
                md={3}
                label={'Postleitzahl'}
                name={`${addressPrefix}ZipCode`}
                value={zipCode}
                disabled={props.disabled}
                onChange={(s: string) => setZipCode(s)}
            />
            <UserTextField
                xs={12}
                md={9}
                label={'Stadt'}
                name={`${addressPrefix}City`}
                value={city}
                disabled={props.disabled}
                onChange={(s: string) => setCity(s)}
            />
        </Grid>
    );
};

export {
    UserDetailsForm,
    AddressForm,
};
