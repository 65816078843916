import React from 'react';
import { StateEntry } from './Types';
import {
    Address,
    ConfigurationStatus,
    EditDeckConfiguration,
    ExtraItem,
    FreeItemWithFakeId,
} from '../Types/ConfigurationTypes';

const generateStateEntryWithEmptyChangeFunction = <T>(value: T): StateEntry<T> => {
    return [
        value,
        () => {

        },
    ];
};

const generateDefaultContext: () => EditDeckConfiguration = () => {
    return {
        configurationStatus: generateStateEntryWithEmptyChangeFunction<ConfigurationStatus | null>(null),
        width: generateStateEntryWithEmptyChangeFunction<number>(4),
        depth: generateStateEntryWithEmptyChangeFunction<number>(2),
        height: generateStateEntryWithEmptyChangeFunction<number>(2.5),
        deckType: generateStateEntryWithEmptyChangeFunction<string | null>(null),
        roofType: generateStateEntryWithEmptyChangeFunction<string | null>(null),
        colorType: generateStateEntryWithEmptyChangeFunction<string | null>(null),
        coverType: generateStateEntryWithEmptyChangeFunction<string | null>(null),
        extraItems: generateStateEntryWithEmptyChangeFunction<ExtraItem[]>([]),
        freeItems: generateStateEntryWithEmptyChangeFunction<FreeItemWithFakeId[]>([]),
        billingAddress: generateStateEntryWithEmptyChangeFunction<Address | null>(null),
        shippingAddress: generateStateEntryWithEmptyChangeFunction<Address | null>(null),
    };
};

const defaultConfigurationContext = generateDefaultContext();

const ConfigurationContext = React.createContext<EditDeckConfiguration>(defaultConfigurationContext);

export default ConfigurationContext;

export {
    generateDefaultContext,
};
