exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-App__AppLayoutContainer--gy8km {\n    display: flex;\n\n    flex: 1;\n    height: 100%;\n    position: relative;\n    width: 100%;\n\n    overflow: hidden;\n}\n\n.src-App__AppLayoutContainerSidebarOpen--1kA_p {\n\n}\n\n.src-App__ContentIsMobile--1XwY2 {\n    flex-shrink: 0;\n}\n\n.src-App__Content--1RCPT {\n    width: 100%;\n    display: flex;\n    flex-flow: column;\n    overflow: hidden;\n}\n\n.src-App__Sidebar--2dM8D {\n    flex-shrink: 0;\n    border-right: rgba(0, 0, 0, 0.12) 1px solid;\n}\n", ""]);

// exports
exports.locals = {
	"AppLayoutContainer": "src-App__AppLayoutContainer--gy8km",
	"AppLayoutContainerSidebarOpen": "src-App__AppLayoutContainerSidebarOpen--1kA_p",
	"ContentIsMobile": "src-App__ContentIsMobile--1XwY2",
	"Content": "src-App__Content--1RCPT",
	"Sidebar": "src-App__Sidebar--2dM8D"
};