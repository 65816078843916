exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Site-WelcomeSite-WelcomeSite__CenterCard--WWj8X {\n    max-width: 100%;\n    height: 100%;\n    width: 600px;\n    margin: 0 auto;\n    display: flex;\n    flex-shrink: 0;\n    flex-flow: column;\n    align-items: center;\n    justify-content: flex-start;\n    position: relative;\n}\n\n.src-Site-WelcomeSite-WelcomeSite__CenterText--3zd5P {\n    text-align: center;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.src-Site-WelcomeSite-WelcomeSite__Divider--al3JI {\n    width: 100%;\n    margin: 1em 0;\n}\n\n.src-Site-WelcomeSite-WelcomeSite__ButtonBar--13_1w {\n    padding: 1em 0 0 0;\n    display: flex;\n    flex-flow: row;\n    justify-content: center;\n}\n\n.src-Site-WelcomeSite-WelcomeSite__ButtonBar--13_1w > * {\n    margin: 0 0.5em;\n}\n\n.src-Site-WelcomeSite-WelcomeSite__FlexCenter--2eNb4 {\n    display: flex;\n    justify-content: center;\n}\n\n.src-Site-WelcomeSite-WelcomeSite__LoginFieldsContainer--2hAw2 {\n    max-width: 90%;\n    width: 400px;\n}\n\n.src-Site-WelcomeSite-WelcomeSite__LegalLink--3RffS {\n    position: absolute;\n    bottom: 0;\n    right: 0;\n}\n\n.src-Site-WelcomeSite-WelcomeSite__LegalLink--3RffS > a {\n    margin: 0 0.5em;\n    cursor: pointer;\n}\n\n.src-Site-WelcomeSite-WelcomeSite__LegalLink--3RffS > a:hover {\n    color: #77a630;\n}\n", ""]);

// exports
exports.locals = {
	"CenterCard": "src-Site-WelcomeSite-WelcomeSite__CenterCard--WWj8X",
	"CenterText": "src-Site-WelcomeSite-WelcomeSite__CenterText--3zd5P",
	"Divider": "src-Site-WelcomeSite-WelcomeSite__Divider--al3JI",
	"ButtonBar": "src-Site-WelcomeSite-WelcomeSite__ButtonBar--13_1w",
	"FlexCenter": "src-Site-WelcomeSite-WelcomeSite__FlexCenter--2eNb4",
	"LoginFieldsContainer": "src-Site-WelcomeSite-WelcomeSite__LoginFieldsContainer--2hAw2",
	"LegalLink": "src-Site-WelcomeSite-WelcomeSite__LegalLink--3RffS"
};