import React, { useEffect } from 'react';
import { Checkbox, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import ConfigurationList from './ConfigurationList';
import { ResolvedExtraItem } from '../../Types/DeckTypes';

const ExtraItems = () => {

    return (
        <ConfigurationList>
            {(currentConfiguration, _, deckType, markCompleted) => {

                const [userExtraItems, setUserExtraItems] = currentConfiguration.extraItems;

                useEffect(() => {
                    markCompleted();
                },        []);

                const modifyExtraItems = (serverExtraItem: ResolvedExtraItem, itemCount: number) => {

                    const cleanedExtraItems = userExtraItems.filter((eI) => {
                        return serverExtraItem.id !== eI.selectedId;
                    });

                    if (itemCount > 0) {
                        cleanedExtraItems.push(
                            {
                                itemCount,
                                selectedId: serverExtraItem.id,
                                isRequired: serverExtraItem.isRequired,
                            },
                        );
                    }

                    setUserExtraItems(cleanedExtraItems);

                };

                return (
                    <List
                        disablePadding
                    >
                        {deckType.extraItems.filter(eI => !eI.shouldBeExcludedOnList).map((serverExtraItem) => {

                            const isSelected = !!userExtraItems.find((userExtraItem) => {
                                return userExtraItem.selectedId === serverExtraItem.id;
                            });

                            return (
                                <ListItem
                                    disableGutters
                                    key={serverExtraItem.id}
                                >
                                    <ListItemText
                                        primary={serverExtraItem.name}
                                        secondary={serverExtraItem.description}
                                    />
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            disabled={serverExtraItem.isRequired}
                                            onChange={(e: any) => {
                                                modifyExtraItems(serverExtraItem, e.target.checked ? 1 : 0);
                                                markCompleted();
                                            }}
                                            checked={isSelected  || serverExtraItem.isRequired}
                                            color={'primary'}
                                        />
                                    </ListItemSecondaryAction>

                                </ListItem>
                            );
                        })}
                    </List>
                );
            }}

        </ConfigurationList>
    );
};

export default ExtraItems;
