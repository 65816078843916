import Connector from '../Connector';
import { AccountTypes, User } from '../Types/UserTypes';
import React, { useEffect, useState } from 'react';

type UserContextState = {
    user: User | null,
    connectorInstance: Connector | null,
    isAuthenticated: () => boolean,
    isAdmin: () => boolean,
    isAnonymous: () => boolean,
    loginWithCredentials: (eMail: string, passwordInput: string) => Promise<void>,
    loginAnonymous: () => Promise<void>,
    logoutUser: () => Promise<void>,
    updateUser: () => Promise<void>,
    getInstance: () => Connector,
};

const defaultState: UserContextState = {
    user: null,
    connectorInstance: null,
    isAuthenticated: () => false,
    isAnonymous: () => false,
    isAdmin: () => false,
    loginAnonymous: async () => {},
    loginWithCredentials: async () => {},
    logoutUser: async () => {},
    updateUser: async () => {},
    getInstance: () => {
        return new Connector();
    },
};

const UserContext = React.createContext<UserContextState>(defaultState);

const UProvider = UserContext.Provider;
const UserConsumer = UserContext.Consumer;

type ProviderProps = {
    children: JSX.Element,
};

const UserProvider = (props: ProviderProps) => {

    const [user, setUser] = useState<User | null>(null);
    const [connectorInstance, setConnectorInstance] = useState<Connector | null>(null);

    const processConnectionInstance = async (connectionInstance: Promise<Connector>) =>  {
        return connectionInstance.then((connectorInstance) => {

            return Promise.all([
                connectorInstance,
                connectorInstance.getCurrentUser(),
            ]);

        }).then(([connectorInstance, currentUser]) => {

            setUser(currentUser);
            setConnectorInstance(connectorInstance);

        });
    };

    const isAuthenticated = () => {
        return !!user && !!connectorInstance;
    };

    const isAnonymous = () => {
        return !!user && !!connectorInstance && user.accountType === AccountTypes.ANONYMOUS;
    };

    const isAdmin = () => {
        return !!user && !!connectorInstance && user.accountType === AccountTypes.ADMIN;
    };

    const loginAnonymous = () => {
        const createAnonymousUserPromise = Connector.createInstanceFromAnonymous();
        return processConnectionInstance(createAnonymousUserPromise);
    };

    const loginWithCredentials = (eMail: string, passwordInput: string) => {
        const createConnectionWithUserCredentialsPromise =
            Connector.createInstanceFromCredentials(eMail, passwordInput);
        return processConnectionInstance(createConnectionWithUserCredentialsPromise);
    };

    const updateUser = async () => {
        const currentUser = await connectorInstance!.getCurrentUser();
        setUser(currentUser);
    };

    const logoutUser = async () => {

        if (!connectorInstance) {
            return;
        }
        await connectorInstance.removeInstance();
        await setUser(null);
        await setConnectorInstance(null);
    };

    const getInstance = () => {

        if (!connectorInstance) {
            throw Error('connectorInstance is not defined.');
        }

        return connectorInstance;
    };

    useEffect(() => {
        const localStorageUserPromise = Connector.createInstanceFromLocalStorage();
        processConnectionInstance(localStorageUserPromise);
    },        []);

    const providerState: UserContextState = {
        user,
        connectorInstance,
        isAuthenticated,
        isAnonymous,
        isAdmin,
        loginWithCredentials,
        loginAnonymous,
        logoutUser,
        getInstance,
        updateUser,
    };

    return (
        <UProvider value={providerState}>
            {props.children}
        </UProvider>
    );

};

export {
    UserContext,
    UserProvider,
    UserConsumer,
};
