exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Components-Drawing-Drawing__DrawingRoot--375aQ {\n    width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"DrawingRoot": "src-Components-Drawing-Drawing__DrawingRoot--375aQ"
};