import React, { useContext } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import ConfigurationContext from '../../Context/ConfigurationContext';
import { DeckType } from '../../Types/DeckTypes';
import StepContext, { StepState } from '../../Context/StepContext';
import { DataContext } from '../../Context/DataContext';
import { EditDeckConfiguration } from '../../Types/ConfigurationTypes';

type InternalProps = {};

type ExternalProps = {
    children: (
        userConfiguration: EditDeckConfiguration,
        stepContext: StepState,
        deckType: DeckType,
        markCompleted: () => void,
    ) => JSX.Element;
};

type Props = InternalProps & ExternalProps;

const ConfigurationList = (props: Props) => {

    const userConfiguration = useContext(ConfigurationContext);
    const stepContext = useContext(StepContext);
    const dataContext = useContext(DataContext);

    const [, setCompleted] = stepContext.isCompleted[stepContext.currentStep];

    const [selectedDeckTypeId] = userConfiguration.deckType;

    const selectedDeckType = dataContext.decks.find(d => d.id === selectedDeckTypeId);

    // TODO: Think about this. Maybe we want to output an error message at this point, because this should not happen.
    // We wont invalidate older configurations, but I am not sure, how to do this.

    if (!selectedDeckTypeId || !selectedDeckType) {
        return (
            <List disablePadding>
                <ListItem disableGutters>
                    <ListItemText
                        primary={'Keine Terrassenüberdachung gewählt.'}
                        secondary={'Bitte wählen Sie zuerst eine Terrassenüberdachung.'}
                    />
                </ListItem>
            </List>
        );
    }

    return props.children(userConfiguration, stepContext, selectedDeckType, () => setCompleted(true));
};

export default ConfigurationList;
