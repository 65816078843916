import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import React from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import JssProvider from 'react-jss/lib/JssProvider';
import { create, JSS, JSSOptions } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName();
const jss: JSS = create(jssPreset());

// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
const jssInsertionPoint: HTMLElement | null = document.getElementById('jss-insertion-point');
if (jssInsertionPoint) {
    const jssOptions: Partial<JSSOptions> = {
        insertionPoint: jssInsertionPoint,
    };
    jss.setup(jssOptions);
}

const history = createBrowserHistory();

const appContext = (
    <JssProvider jss={jss} generateClassName={generateClassName}>
        <Router
            history={history}
        >
            <App/>
        </Router>
    </JssProvider>
);

ReactDOM.render(
    appContext,
    document.getElementById('root') as HTMLElement,
);
