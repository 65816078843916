exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-Site-ConfigurationSite-ConfigurationSite__ConfigurationSite--tUOVJ {\n    min-height: 100%;\n    box-sizing: border-box;\n    display: flex;\n    flex-flow: column;\n    justify-content: space-between;\n}\n\n.src-Site-ConfigurationSite-ConfigurationSite__ConfigurationOverview--2D-jz {\n\n    padding: 1em;\n    margin: 0 -2em -2em -2em;\n\n\n    border-top: rgba(0, 0, 0, 0.12) solid 1px;\n\n    display: flex;\n\n    justify-content: space-between;\n    align-items: center;\n\n}\n\n.src-Site-ConfigurationSite-ConfigurationSite__ConfigurationOverviewMobile--1CYel {\n    padding: 0;\n}\n\n.src-Site-ConfigurationSite-ConfigurationSite__ConfigurationStepper--2vrge {\n\n    margin: -2em -2em 0 -2em;\n    padding: 1em;\n\n\n    border-bottom: rgba(0, 0, 0, 0.12) solid 1px;\n\n}\n\n.src-Site-ConfigurationSite-ConfigurationSite__ConfigurationStepperMobile--1qoiO {\n    padding: 0;\n}\n\n.src-Site-ConfigurationSite-ConfigurationSite__ConfigurationContent--35kxc {\n    flex-grow: 1;\n    padding: 1em 0;\n\n    max-width: 1000px;\n    width: 100%;\n    margin: 0 auto;\n\n    box-sizing: border-box;\n}\n\n.src-Site-ConfigurationSite-ConfigurationSite__PriceViewWithExtender--3lnuP {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    max-width: 600px;\n}\n\n.src-Site-ConfigurationSite-ConfigurationSite__PriceViewWithExtender--3lnuP > * {\n    margin-right: 0.5em;\n}\n\n.src-Site-ConfigurationSite-ConfigurationSite__PriceViewWithExtender--3lnuP > ul {\n    flex-grow: 1;\n}\n\n.src-Site-ConfigurationSite-ConfigurationSite__NextButton--16itH {\n    margin: 0 0.5em;\n}\n", ""]);

// exports
exports.locals = {
	"ConfigurationSite": "src-Site-ConfigurationSite-ConfigurationSite__ConfigurationSite--tUOVJ",
	"ConfigurationOverview": "src-Site-ConfigurationSite-ConfigurationSite__ConfigurationOverview--2D-jz",
	"ConfigurationOverviewMobile": "src-Site-ConfigurationSite-ConfigurationSite__ConfigurationOverviewMobile--1CYel",
	"ConfigurationStepper": "src-Site-ConfigurationSite-ConfigurationSite__ConfigurationStepper--2vrge",
	"ConfigurationStepperMobile": "src-Site-ConfigurationSite-ConfigurationSite__ConfigurationStepperMobile--1qoiO",
	"ConfigurationContent": "src-Site-ConfigurationSite-ConfigurationSite__ConfigurationContent--35kxc",
	"PriceViewWithExtender": "src-Site-ConfigurationSite-ConfigurationSite__PriceViewWithExtender--3lnuP",
	"NextButton": "src-Site-ConfigurationSite-ConfigurationSite__NextButton--16itH"
};