import { EditDeckConfiguration, ServerDeckConfiguration } from '../Types/ConfigurationTypes';
import { PriceType } from '../Types/PriceType';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context/UserContext';

const usePriceWithEditConfiguration = (currentConfiguration: EditDeckConfiguration) => {
    const defaultPrice: PriceType = {
        deckType: 0,
        roofType: 0,
        colorType: 0,
        coverType: 0,
        totalPrice: 0,
        discountPrice: 0,
        discountPercent: 0,
        extraItems: [],
        freeItems: [],
    };

    const [currentPrice, setCurrentPrice] = useState(defaultPrice);

    const userContext = useContext(UserContext);

    useEffect(() => {

        if (
            !currentConfiguration.width[0] ||
            !currentConfiguration.height[0] ||
            !currentConfiguration.depth[0] ||
            !currentConfiguration.deckType[0] ||
            !currentConfiguration.roofType[0] ||
            !currentConfiguration.colorType[0] ||
            !currentConfiguration.coverType[0]
        ) {
            return;
        }

        const connectionInstance = userContext.getInstance();
        connectionInstance.getCurrentPrice(
            currentConfiguration.width[0],
            currentConfiguration.height[0],
            currentConfiguration.depth[0],
            currentConfiguration.deckType[0],
            currentConfiguration.roofType[0],
            currentConfiguration.colorType[0],
            currentConfiguration.coverType[0],
            currentConfiguration.extraItems[0],
            currentConfiguration.freeItems[0],
        ).then((currentPrice: PriceType) => {
            setCurrentPrice(currentPrice);
        }).catch(() => {

            // We drop this error silent.
            // This is kinda intended behaviour.
        });
    },        [
        currentConfiguration.width[0],
        currentConfiguration.height[0],
        currentConfiguration.depth[0],
        currentConfiguration.deckType[0],
        currentConfiguration.roofType[0],
        currentConfiguration.colorType[0],
        currentConfiguration.coverType[0],
        currentConfiguration.extraItems[0],
        currentConfiguration.freeItems[0],
    ]);

    return currentPrice;
};

const usePriceWithServerConfiguration = (currentConfiguration: ServerDeckConfiguration) => {
    const defaultPrice: PriceType = {
        deckType: 0,
        roofType: 0,
        colorType: 0,
        coverType: 0,
        totalPrice: 0,
        discountPrice: 0,
        discountPercent: 0,
        extraItems: [],
        freeItems: [],
    };

    const [currentPrice, setCurrentPrice] = useState(defaultPrice);

    const userContext = useContext(UserContext);

    useEffect(() => {

        if (
            !currentConfiguration.width ||
            !currentConfiguration.height ||
            !currentConfiguration.depth ||
            !currentConfiguration.deckType ||
            !currentConfiguration.roofType ||
            !currentConfiguration.colorType ||
            !currentConfiguration.coverType
        ) {
            return;
        }

        const connectionInstance = userContext.getInstance();

        const mappedFreeItems = currentConfiguration.freeItems.map((fI) => {
            return {
                ...fI,
                fakeId: Math.random().toString(36).substr(2, 8),
            };
        });

        connectionInstance.getCurrentPrice(
            currentConfiguration.width,
            currentConfiguration.height,
            currentConfiguration.depth,
            currentConfiguration.deckType,
            currentConfiguration.roofType,
            currentConfiguration.colorType,
            currentConfiguration.coverType,
            currentConfiguration.extraItems,
            mappedFreeItems,
        ).then((currentPrice: PriceType) => {
            setCurrentPrice(currentPrice);
        }).catch(() => {

            // We drop this error silent.
            // This is kinda intended behaviour.
        });
    },        [
        currentConfiguration.width,
        currentConfiguration.height,
        currentConfiguration.depth,
        currentConfiguration.deckType,
        currentConfiguration.roofType,
        currentConfiguration.colorType,
        currentConfiguration.coverType,
        currentConfiguration.extraItems,
    ]);

    return currentPrice;
};

export {
    usePriceWithEditConfiguration,
    usePriceWithServerConfiguration,
};
